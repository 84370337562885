import { Button, Container, Grid } from "@mui/material";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import DetailEmployeeAdminIndosat from "../../../../components/IndosatAdmin/Employee/Detail";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { fetchEmployeeExternalById } from "../../../../redux/actions/External/employeesExternalActions";

export default function DetailEmployeeAdminIndosatPage() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  // const employeeInfo = location?.state?.data;
  const { id } = useParams();
  const { employeeByIdExternal } = useSelector(
    (state) => state.employeesExternal
  );
  const [dataEmployee, setDataEmployee] = useState([]);

  useEffect(() => {
    dispatch(fetchEmployeeExternalById(token, id, `clientCode=IST`));
  }, [dispatch, id, token]);

  useEffect(() => {
    setDataEmployee(employeeByIdExternal);
  }, [employeeByIdExternal]);

  if (!employeeByIdExternal) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <div className="">
        <Helmet>
          <title>MyWorkSpace | Employee Details</title>
        </Helmet>
        <div className="">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/admin-indosat/employee")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Employee</span>
            </Button>
            <span
              style={{
                fontSize: 20,
                color: "#255bfc",
                textTransform: "capitalize",
              }}
            >
              {dataEmployee.length !== 0
                ? capitalize(dataEmployee[0]?.user?.name)
                : "-"}
            </span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <DetailEmployeeAdminIndosat
              employeeId={id}
              employee={employeeByIdExternal}
              employeeInfo={dataEmployee}
              setData={setDataEmployee}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

import axios from "axios";
import { API } from "../../api";
import * as types from "../../typeAdminIndosat";

export const fetchAllDashboard = (token, year, month) => {
  return (dispatch) => {
    dispatch({ type: types?.DASHBOARD_BEGIN });
    axios
      .get(`${API}/dashboard?year=${year}&month=${month}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_DASHBOARD,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DASHBOARD_ERROR,
          payload: err.response,
        });
        console.log("ERROR", err);
      });
  };
};

import axios from "axios";
import { API } from "../../redux/api";

export function getAllEmployeeAdminIndosat(page = 1, limit = 10, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${API}/employee?page=${page}&limit=${limit}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

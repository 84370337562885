import Icon from "../../Icon";
import Styles from "./style.module.scss";

export default function ToastAdminIndosat({
  showNotif = false,
  placeholder = "Placeholder",
  isRed = false,
}) {
  return (
    <div
      className={`${Styles.notification} ${isRed && Styles.notificationRed} ${
        showNotif && Styles.show
      }`}
    >
      <Icon
        icon={isRed ? "x-circle" : "circle-check"}
        size={20}
        color={isRed ? "#D42701" : "#fff"}
      />
      {placeholder}
    </div>
  );
}

import * as types from "../../typeAdminIndosat";

const initialstate = {
  allDataBanks: {},
  loadingDataBanks: false,
  errorBanks: {},
};

const BanksAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_BANKS_BEGIN: {
      return {
        ...state,
        loadingDataBanks: true,
      };
    }
    case types.FETCH_ALL_BANKS_ERROR: {
      return {
        ...state,
        loadingDataBanks: false,
        errorBanks: action.payload,
      };
    }

    case types.FETCH_ALL_BANKS: {
      return {
        ...state,
        loadingDataBanks: false,
        allDataBanks: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default BanksAdminIndosatReducer;

import Icon from "../Icon";
import Styles from "./style.module.scss";

const Primary = ({
  icon = "",
  placeHolder = "placeHolder",
  disabled = false,
  onClick = () => {},
  isLoading = false,
}) => {
  return (
    <div
      className={`${Styles.ButtonPrimary} ${
        disabled && Styles.ButtonPrimaryDisabled
      }`}
      onClick={onClick}
    >
      {icon && (
        <div>
          <Icon
            icon={icon}
            size={16}
            color={disabled ? "#9E9E9E" : "#FFFFFF"}
          />
        </div>
      )}
      <span>{isLoading ? "Loading..." : placeHolder}</span>
    </div>
  );
};

// const PrimaryOutlined = ({
//   icon = "",
//   placeHolder = "placeHolder",
//   disabled = false,
//   onClick = () => {},
// }) => {
//   return (
//     <div
//       className={`${Styles.ButtonPrimaryOutlined} ${
//         disabled && Styles.ButtonPrimaryOutlinedDisabled
//       }`}
//       onClick={onClick}
//     >
//       {icon && (
//         <div>
//           <Icon
//             icon={icon}
//             size={16}
//             // color={disabled ? "#98280e" : "#D42701"}
//             className={Styles.icon}
//           />
//         </div>
//       )}
//       <span>{placeHolder}</span>
//     </div>
//   );
// };
const DangerOutlined = ({
  icon = "",
  placeHolder = "placeHolder",
  disabled = false,
  onClick = () => {},
  isLoading = false,
}) => {
  return (
    <div
      className={`${Styles.ButtonDangerOutlined} ${
        disabled && Styles.ButtonDangerOutlinedDisabled
      }`}
      onClick={onClick}
    >
      {icon && (
        <div>
          <Icon
            icon={icon}
            size={16}
            // color={disabled ? "#98280e" : "#D42701"}
            className={Styles.icon}
          />
        </div>
      )}
      <span>{isLoading ? "Loading..." : placeHolder}</span>
    </div>
  );
};

const PrimaryOutlined = ({
  icon = "",
  placeHolder = "placeHolder",
  disabled = false,
  onClick = () => {},
  isLoading = false,
}) => {
  return (
    <div
      className={`${Styles.ButtonPrimaryOutlined} ${
        disabled && Styles.ButtonPrimaryOutlinedDisabled
      }`}
      onClick={onClick}
    >
      {icon && (
        <div>
          <Icon
            icon={icon}
            size={16}
            color={disabled ? "#9E9E9E" : "#FFFFFF"}
          />
        </div>
      )}
      <span>{isLoading ? "Loading..." : placeHolder}</span>
    </div>
  );
};

export default function Button({
  type = "primary",
  placeHolder = "placeHolder",
  disabled = false,
  icon = "",
  onClick = () => {},
  isLoading = false,
}) {
  switch (type) {
    case "primary":
      return (
        <Primary
          type={type}
          placeHolder={placeHolder}
          disabled={disabled}
          icon={icon}
          onClick={!disabled && onClick}
          isLoading={isLoading}
        />
      );
    case "primary-outlined":
      return (
        <PrimaryOutlined
          type={type}
          placeHolder={placeHolder}
          disabled={disabled}
          icon={icon}
          onClick={onClick}
          isLoading={isLoading}
        />
      );
    case "danger":
      return (
        <DangerOutlined
          type={type}
          placeHolder={placeHolder}
          disabled={disabled}
          icon={icon}
          onClick={onClick}
          isLoading={isLoading}
        />
      );

    default:
      return (
        <Primary
          type={type}
          placeHolder={placeHolder}
          disabled={disabled}
          icon={icon}
          onClick={!disabled && onClick}
          isLoading={isLoading}
        />
      );
  }
}

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./storePromo.css";
import {
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Paper,
  TableHead,
  Button
} from "@mui/material";
import { useHistory } from "react-router";
import rows from '../../_dashboard/appNewCustBottom/dataDummy';
import HeaderItem from "../../utils/HeaderItem/HeaderItem";

function AppRecentProduct() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterBM, setFilterBM] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <Paper
        className="container__table-customer-external"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <HeaderItem initial="Recent Product Promo" />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Promo type</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Period</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ id, CustomerName, ProductName, Store, Date }) => (
              <TableRow key={id}>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" sx={{ bgColor: "grey" }} />
                    <Typography variant="subtitle2" noWrap>
                      {CustomerName}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{ProductName}</TableCell>
                <TableCell>{Store}</TableCell>
                <TableCell>{Date}</TableCell>
                <TableCell align="right">
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#1571DE",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    onClick={()=> history.push('/admin/store-details')}
                  >
                    Lihat Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
    // <div className="recentStore">
    //   <div className="wrapperStorePromo">
    //     <span className="rectangleYellowStorePromo"></span>
    //     <h3 className="chartTitleStorePromo">Recent Store Promo</h3>
    //   </div>
    //   <div className="titleTopNavStorePromo">
    //     <span className="custNameStorePromo">Promo Type</span>
    //     <span className="productNameStorePromo">Product</span>
    //     <span className="StorePromo">Store</span>
    //     <span className="dateStorePromo">Date</span>
    //   </div>
    //   <div>
    //     <hr style={{ width: "100%", color: "#ECE6E6" }} />
    //   </div>
    //   <div className="boxProductCustBottom">
    //     <img src={icon_circle_cust} alt="" />
    //     <span className="itemsStorePromoName">Food Promo</span>
    //     <span className="itemsStorePromoProduct">Susu Bendera</span>
    //     <span className="itemsStorePromo"> Indomaret</span>
    //     <span className="itemsDateStorePromo"> 31 December 2021</span>
    //     <div className="detailButton">
    //       <Link to="/storeDetails">
    //         <span style={{ fontSize: 14 }}>Detail</span>
    //       </Link>
    //     </div>
    //   </div>
    //   <div>
    //     <hr style={{ width: "100%", color: "#ECE6E6" }} />
    //   </div>
    //   <div className="boxProductCustBottom">
    //     <img src={icon_circle_cust} alt="" />
    //     <span className="itemsStorePromoName">Floor Display</span>
    //     <span className="itemsStorePromoProductDisplay">Teh Celup Sosro</span>
    //     <span className="itemsStorePromoDisplay"> Alfamart</span>
    //     <span className="itemsDateStorePromoDisplay">31 December 2021</span>
    //     <span className="detailButton">Detail</span>
    //   </div>
    //   <div>
    //     <hr style={{ width: "100%", color: "#ECE6E6" }} />
    //   </div>
    //   <div className="boxProductCustBottom">
    //     <img src={icon_circle_cust} alt="" />
    //     <span className="itemsStorePromoName">Katalog</span>
    //     <span className="itemsStorePromoKatalog">Sprite</span>
    //     <span className="itemsStorePromoKatalogName">Alfamidi</span>
    //     <span className="itemsDateStorePromoKatalog"> 31 December 2021</span>
    //     <span className="detailButton">Detail</span>
    //   </div>
    // </div>
  );
}

export default AppRecentProduct;

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import Styles from "./CustomTablePagination.module.css";
import { usePagination, DOTS } from "./UsePagination";

const limitOptions = [5, 10, 25, 50];

export default function CustomTablePagination({
  limit,
  setLimit,
  totalPage,
  page,
  setPage,
  siblingCount = 1,
  className,
  isAdminIndosat = false,
  totalData = 0,
}) {
  const paginationRange = usePagination({
    page,
    siblingCount,
    totalPage,
  });

  const onNext = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const onPrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = page === 1;
  const nextDisabled = page === lastPage;

  if (!isAdminIndosat) {
    if (page === 0 || paginationRange?.length < 2) {
      // return null;
      return (
        <div className={`${Styles.PaginationContainer} ${className}`}>
          <div className={Styles.SetLimit}>
            <label>Show</label>
            <Select
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
              }}
              className={Styles.Select}
            >
              {limitOptions.map((option) => (
                <MenuItem key={option} className={Styles.Option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <span>Row per page</span>
          </div>
          <div className={Styles.Pages}>
            <ul>
              <li>
                <ArrowBackIos
                  className={`${Styles.ArrowIcon} ${Styles.Disabled}`}
                  onClick={onPrevious}
                />
              </li>
              {paginationRange.map((each) => {
                if (each === DOTS) {
                  return (
                    <li
                      key={each + Math.random()}
                      className={`${Styles.NormalPageNumber} ${Styles.Disabled}`}
                    >
                      &#8230;
                    </li>
                  );
                }
                return (
                  <li
                    key={each + Math.random()}
                    className={`${
                      page === each
                        ? Styles.ActivePageNumber
                        : Styles.NormalPageNumber
                    } ${Styles.PageNumber}`}
                    onClick={() => setPage(each)}
                  >
                    {each}
                  </li>
                );
              })}

              <li>
                <ArrowForwardIos
                  className={`${Styles.ArrowIcon} ${Styles.Disabled}`}
                  onClick={onNext}
                />
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }

  return isAdminIndosat ? (
    <div className={`${Styles.paginationWrapper} ${className}`}>
      <div className={Styles.PaginationContainerIndosat}>
        <div className={Styles.SetLimit}>
          <label>Menampilkan</label>
          <Select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(1);
            }}
            className={Styles.Select}
          >
            {limitOptions.map((option) => (
              <MenuItem key={option} className={Styles.Option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <span>Data, dari {totalData} data</span>
        </div>
      </div>

      <div className={Styles.Pages}>
        <ul>
          <li>
            <ArrowBackIos
              className={`${Styles.ArrowIcon} ${
                prevDisabled && Styles.Disabled
              }`}
              onClick={onPrevious}
            />
          </li>
          {paginationRange.map((each) => {
            if (each === DOTS) {
              return (
                <li
                  key={each + Math.random()}
                  className={`${Styles.NormalPageNumber} ${Styles.Disabled}`}
                >
                  &#8230;
                </li>
              );
            }
            return (
              <li
                key={each + Math.random()}
                className={`${
                  page === each
                    ? Styles.ActivePageNumber
                    : Styles.NormalPageNumber
                } ${Styles.PageNumber}`}
                onClick={() => setPage(each)}
              >
                {each}
              </li>
            );
          })}

          <li>
            <ArrowForwardIos
              className={`${Styles.ArrowIcon} ${
                nextDisabled && Styles.Disabled
              }`}
              onClick={onNext}
            />
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <div className={Styles.PaginationContainer}>
      <div className={Styles.SetLimit}>
        <label>Show</label>
        <Select
          value={limit}
          onChange={(e) => {
            setLimit(e.target.value);
            setPage(1);
          }}
          className={Styles.Select}
        >
          {limitOptions.map((option) => (
            <MenuItem key={option} className={Styles.Option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <span>Row per page</span>
      </div>
      <div className={Styles.Pages}>
        <ul>
          <li>
            <ArrowBackIos
              className={`${Styles.ArrowIcon} ${
                prevDisabled && Styles.Disabled
              }`}
              onClick={onPrevious}
            />
          </li>
          {paginationRange.map((each) => {
            if (each === DOTS) {
              return (
                <li
                  key={each + Math.random()}
                  className={`${Styles.NormalPageNumber} ${Styles.Disabled}`}
                >
                  &#8230;
                </li>
              );
            }
            return (
              <li
                key={each + Math.random()}
                className={`${
                  page === each
                    ? Styles.ActivePageNumber
                    : Styles.NormalPageNumber
                } ${Styles.PageNumber}`}
                onClick={() => setPage(each)}
              >
                {each}
              </li>
            );
          })}

          <li>
            <ArrowForwardIos
              className={`${Styles.ArrowIcon} ${
                nextDisabled && Styles.Disabled
              }`}
              onClick={onNext}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

import React, { useLayoutEffect } from "react";
import style from "./style.module.scss";
export default function Modal({
  children,
  onClose = () => {},
  bg = "00000080",
  isTransparent = false,
  isSlider = false,
  isResponsive = false,
  isOpen = false,
  ...props
}) {
  useLayoutEffect(() => {
    if (isOpen) {
      console.log("masuk");
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  const handlClose = () => {
    document.body.classList.remove("no-scroll");
    onClose();
  };

  if (isSlider) {
    return (
      <div className={`${style.slider} ${style.isOpen}`}>
        {children}
        <div
          className={`${style.overlay} ${
            style[isTransparent ? "transparent" : ""]
          }`}
          onClick={handlClose}
        />
      </div>
    );
  }

  return (
    <div
      className={`${style.background}  ${
        isResponsive && style.outsideResponsive
      } ${
        !isOpen && style.hidden
      }`}
    >
      {children}
      <div
        className={`${style.outside}`}
        style={{
          background: bg,
        }}
        onClick={handlClose}
      />
    </div>

    // </div>
  );
}

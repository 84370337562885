/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/my-workspace.png";
import useWindowDimensions from "../../SuperAdmin/_utils/useDimensions";
import { Drawer } from "@mui/material";
import { useRouteMatch } from "react-router";

import moment from "moment";
import Icon from "../../Icon";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const dataAdminMWS = [
  // {
  //   display_name: "Dashboard",
  //   route: "",
  //   icon: "bx bxs-dashboard",
  // },
  {
    display_name: "Employee",
    route: "/employee",
    icon: "bx bxs-user-account",
  },
  {
    display_name: "Absensi",
    route: "/absence",
    icon: "bx bxs-calendar-alt",
  },
  // {
  //   display_name: "Daftar Nomor",
  //   route: "/number-list",
  //   svg: "sim-card",
  // },
  // {
  //   display_name: "Daftar Promotor",
  //   route: "/promotor-list",
  //   svg: "group-user-solid",
  // },
  // {
  //   display_name: "Penjualan",
  //   route: "/sales",
  //   svg: "cashier-machine",
  // },
];

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";


  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        {props?.svg ? (
          <Icon icon={props?.svg} size={20} />
        ) : (
          <i className={props.icon}></i>
        )}

        <span>{props.title}</span>
      </div>
    </div>
  );
};

const userName = localStorage.getItem("userName");
const clientName = localStorage.getItem("clientName");
const employeeId = localStorage.getItem("employeeId");
const joinDate = localStorage.getItem("joinDate");
const salaryPayDay = localStorage.getItem("salaryPayDay");

let preFillMessage = `
Hallo Nina, saya ingin bertanya nih, ini data-data saya yaa:

Nama: ${userName}
NRK: ${employeeId}
Client: ${clientName}
Join Date: ${moment(joinDate).format("YYYY-MM-DD")}
Tanggal Gaji (Setiap Bulan): ${salaryPayDay}
Kendala:
`;

const handleSendMessage = () => {
  const phoneNumber = "6282310997063";
  const encodedMessage = encodeURIComponent(preFillMessage);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  window.open(whatsappLink, "_blank");
};

const SidebarAdminIndosat = ({ onOpenSidebar, onCloseSidebar, ...props }) => {
  const isLoan = true;
  const isLocation = true;
  const isAttendance = true;
  let { url } = useRouteMatch();
  const { width } = useWindowDimensions();
  const activeItem = dataAdminMWS.findIndex(
    (item) => `${url}` + item.route === props.location.pathname
  );

  // const activeItem = sidebar_items.findIndex(
  //   (item) => `${url}` + item.route === props.location.pathname
  // );

  const isActive = (data) => {
    if (data.split("/").length < 1) {
      return true;
    } else if (data.split("/")[1] === props.location.pathname.split("/")[2]) {
      return true;
    }
    return false;
  };

  const { pathname } = useLocation();

  return (
    <>
      <Drawer
        anchor="left"
        open={width > 1200 || onOpenSidebar}
        onClose={onCloseSidebar}
        variant={width > 1200 ? "permanent" : "temporary"}
        PaperProps={{
          sx: { width: 281, position: "unset" },
        }}
      >
        <div style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }} className={`sidebar ${!onOpenSidebar && "close"}`}>
          <Link
            // to="/admin"
            to="/admin-indosat"
          >
            <div className="sidebar__logo">
              <img src={logo} alt="company logo" />
              <span>MyWorkSpace</span>
            </div>
          </Link>
          <div
            className={`wrapper__sidebar_indosat ${pathname.startsWith("/admin-indosat") && "fixedSideBar"
              } `}
          >
            <div className="item__container">
              {dataAdminMWS.map((item, index) => (
                <Link to={`/admin-indosat` + item.route} key={index}>
                  <SidebarItem
                    title={item.display_name}
                    icon={item.icon}
                    svg={item.svg}
                    active={isActive(item.route)}
                  />
                </Link>
              ))}
            </div>
            {/* <div className="item__container_2">
              <Link to={'/admin-indosat/setting'}>
                <SidebarItem
                  title={'Pengaturan'}
                  svg={''}
                  icon='bx bxs-cog'
                  active={isActive('/setting')}
                />
              </Link>
            </div> */}

          </div>


        </div>
      </Drawer>
    </>
  );
};

export default SidebarAdminIndosat;

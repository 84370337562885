import React from "react";
import { Helmet } from "react-helmet";
import TransactionDetail from "../../../components/IndosatAdmin/TransactionDetail";

export default function AdminIndosatTransactionDetail() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Penjualan</title>
      </Helmet>

      <TransactionDetail />

      {/* <PawnDetailAdminMWSLayout data={loanDataById} dataById={ pawn } token={ token } id={ id}/> */}
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

import DropdownMui from "../../DropdownMUI";
import SearchbarFMCG from "../../FMCG/SearchbarFMCG/SearchbarFMCG";

import TabHeader from "../../Atom/TabHeader";
import Button from "../../Button";
import Icon from "../../Icon";
import Table from "../Table";

import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import USER_IMAGE from "../../../assets/images/ava-default.png";
import { createStock, updateStatusStock } from "../../../async/AdminIndosat";
import { rupiahFormat } from "../../../helpers/formatRupiah";
import { titleCase } from "../../../helpers/titleCase";
import { useDebounce } from "../../../hook/useDebounce";
import { fetchAllStock } from "../../../redux/actions/AdminIndosat/stockActions";
import ToastAdminIndosat from "../../Atom/ToastAdminIndosat";
import Modal from "../../Modal";
import AddStockModal from "../ModalAdminIndosat/AddStockModal";
import DetailStockModal from "./DetailStockModal";

// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip as TooltipReact,
//   CartesianGrid,
// } from "recharts";

const typeOptions = ["Normal", "Silver", "Bronze"];
const statusOptions = ["Tersedia", "Terjual", "Void"];

export default function NumberListIndosat() {
  const [searchNumber, setSearchNumber] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const debounceSearh = useDebounce(searchNumber, 500);

  // eslint-disable-next-line no-unused-vars
  const { allDataStock, errorStock, loadingDataStock } = useSelector(
    (state) => state.stockAdminIndosat
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showDetail, setShowDetail] = useState(null);

  useEffect(() => {
    dispatch(
      fetchAllStock(
        token,
        +page || 1,
        +limit || 10,
        debounceSearh,
        selectedStatus
          ? selectedStatus === "Tersedia"
            ? "AVAILABLE"
            : selectedStatus === "Terjual"
            ? "SOLD"
            : selectedStatus === "Void"
            ? "VOID"
            : selectedStatus
          : "",
        selectedType?.toUpperCase() || ""
      )
    );
  }, [
    dispatch,
    token,
    page,
    limit,
    debounceSearh,
    selectedStatus,
    selectedType,
  ]);
  // const [chartWidth, setChartWidth] = useState(0);
  // const containerRef = useRef(null);
  const [showModalUpload, setShowModalUpload] = useState(false);

  // useEffect(() => {
  //   const updateWidth = () => {
  //     if (containerRef.current) {
  //       setChartWidth(containerRef.current.offsetWidth);
  //     }
  //   };

  //   const resizeObserver = new ResizeObserver(updateWidth);
  //   if (containerRef.current) {
  //     resizeObserver.observe(containerRef.current);
  //   }

  //   return () => {
  //     if (containerRef.current) {
  //       resizeObserver.unobserve(containerRef.current);
  //     }
  //   };
  // }, []);

  const [files, setFiles] = useState(null);

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [error, setError] = useState("");

  const handleUploadStock = async () => {
    try {
      setIsLoadingCreate(true);
      const formData = new FormData();
      formData.append("file", files);
      await createStock(token, formData);
      setIsLoadingCreate(false);
      setSuccessCreate(true);
      setError("");
      setFiles(null);
      dispatch(
        fetchAllStock(
          token,
          +page,
          +limit,
          debounceSearh,
          selectedStatus
            ? selectedStatus === "Tersedia"
              ? "AVAILABLE"
              : selectedStatus === "Terjual"
              ? "SOLD"
              : selectedStatus === "Void"
              ? "VOID"
              : selectedStatus
            : "",
          selectedType?.toUpperCase() || ""
        )
      );
    } catch (error) {
      setIsLoadingCreate(false);
      setSuccessCreate(false);
      setError(error?.response?.data?.error);
      console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(null);

  const handleUpdateStatus = async (id) => {
    try {
      // e.preventDefault();
      setIsLoadingUpdate(true);
      await updateStatusStock(token, id);
      setIsLoadingUpdate(false);
      setSuccessUpdate(true);
      setErrorUpdate(null);
      dispatch(
        fetchAllStock(
          token,
          +page,
          +limit,
          debounceSearh,
          selectedStatus
            ? selectedStatus === "Tersedia"
              ? "AVAILABLE"
              : selectedStatus === "Terjual"
              ? "SOLD"
              : selectedStatus === "Void"
              ? "VOID"
              : selectedStatus
            : "",
          selectedType?.toUpperCase() || ""
        )
      );
    } catch (error) {
      setIsLoadingUpdate(false);
      setSuccessUpdate(false);
      setErrorUpdate({
        isValidate: true,
        message: error?.response?.data?.error,
      });
      console.log(error);
    }
  };

  const templateData = useMemo(() => {
    return {
      data:
        allDataStock?.data?.length > 0
          ? allDataStock?.data?.map((el, idx) => {
              return {
                id: idx,
                _id: el?._id,
                msisdn: el?.msisdn,
                iccid: el?.iccid,
                type: el?.type ? titleCase(el?.type) : "-",
                price: el?.price ? rupiahFormat(el?.price) : "Rp 0",
                img: el?.employeeID
                  ? el?.user?.profilePicture
                    ? el?.user?.profilePicture
                    : USER_IMAGE
                  : null,
                userName: el?.user?.name,
                location: !el?.employeeID ? "Gudang" : "Assign",
                status: el?.status
                  ? el?.status === "AVAILABLE"
                    ? "Tersedia"
                    : el?.status === "SOLD"
                    ? "Terjual"
                    : el?.status === "VOID"
                    ? "Void"
                    : el?.status
                  : null,
              };
            })
          : [],
      columns: [
        {
          name: "",
          title: <TabHeader text="No MSISDN & ICCID" />,
          renderData: (row) => (
            <div className={Styles.numberListRow}>
              <span>{row?.msisdn || "-"}</span>
              <span>{row?.iccid || "-"}</span>
            </div>
          ),
        },
        {
          name: "type",
          title: <TabHeader text="Tipe Kartu" />,
        },
        {
          name: "price",
          title: <TabHeader text="Harga" />,
        },
        {
          name: "location",
          title: <TabHeader text="Lokasi Kartu" />,
          renderData: (row) => (
            <div className={Styles.locationRow}>
              <div>
                {row?.location === "Gudang" ? (
                  <Icon icon={"building-company"} size={20} color={"#1571DE"} />
                ) : (
                  <img src={row?.img} alt="" />
                )}
              </div>
              <span>
                {row?.location === "Gudang"
                  ? row?.location
                  : row.userName || "-"}
              </span>
            </div>
          ),
        },
        {
          name: "status",
          title: <TabHeader text="Status" />,
          renderData: (row) => (
            <div
              className={`${Styles.statusRow} ${
                row?.status === "Terjual" && Styles.statusSoldRow
              } ${row?.status === "Void" && Styles.statusVoidRow}`}
            >
              {row?.status}
            </div>
          ),
        },
        {
          name: "",
          title: "",
          renderData: (row) => (
            <Tooltip title={"Tandai Sebagai Void"}>
              <div
                className={`${Styles.iconSimcard} ${
                  row?.status === "Void" ||
                  row?.status === "Terjual" ||
                  row?.location !== "Gudang"
                    ? Styles.disabled
                    : ""
                }`}
                onClick={(e) => {
                  if (
                    row?.status === "Void" ||
                    row?.status === "Terjual" ||
                    row?.location !== "Gudang"
                  ) {
                    return;
                  } else {
                    e.stopPropagation();
                    handleUpdateStatus(row?._id);
                  }
                }}
              >
                <Icon
                  icon={"simcard-void"}
                  size={20}
                  color={
                    row?.status === "Void" ||
                    row?.status === "Terjual" ||
                    row?.location !== "Gudang"
                      ? "#C2C2C2"
                      : "#D42701"
                  }
                />
              </div>
            </Tooltip>
          ),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDataStock]);

  useEffect(() => {
    if (
      allDataStock?.totalPage === undefined ||
      allDataStock?.totalPage === null
    ) {
      return; // Tidak melakukan apa-apa jika totalPage tidak valid
    }

    const previousPage = Number(page) || 1; // Konversi page ke angka, default ke 1
    if (allDataStock?.totalPage <= 1) {
      setPage(1); // Atur ke halaman pertama jika hanya ada 1 atau 0 halaman
    } else {
      const validPage = Math.max(
        1,
        Math.min(previousPage, allDataStock.totalPage)
      );
      setPage(validPage);
    }
  }, [page, allDataStock]);

  useEffect(() => {
    if (successUpdate) {
      const timeout = setTimeout(() => {
        setSuccessUpdate(false);
      }, 3000); // Ganti 3000 dengan durasi waktu dalam milidetik yang diinginkan

      return () => clearTimeout(timeout); // Bersihkan timeout jika efek ini dijalankan ulang
    }
  }, [successUpdate]);

  useEffect(() => {
    if (errorUpdate?.isValidate) {
      const timeout = setTimeout(() => {
        setErrorUpdate((prev) => ({
          ...prev,
          isValidate: false,
        }));
      }, 3000); // Ganti 3000 dengan durasi waktu dalam milidetik yang diinginkan

      return () => clearTimeout(timeout); // Bersihkan timeout jika efek ini dijalankan ulang
    }
  }, [errorUpdate]);

  // const data = [
  //   { name: "January", total: 40 },
  //   { name: "February", total: 35 },
  //   { name: "March", total: 50 },
  //   { name: "April", total: 30 },
  //   { name: "May", total: 60 },
  //   { name: "June", total: 70 },
  //   { name: "July", total: 55 },
  //   { name: "August", total: 45 },
  //   { name: "September", total: 65 },
  //   { name: "October", total: 0 },
  //   { name: "November", total: 85 },
  //   { name: "December", total: 95 },
  // ];

  return (
    <div className={Styles.container}>
      <div className={Styles.containerNumberList}>
        {/* <div className={Styles.temp}>
          {chartWidth > 0 && (
            <BarChart
              width={chartWidth}
              height={400}
              data={data}
              margin={{ top: 24, right: 32, left: 0, bottom: 20 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
              />
              <XAxis dataKey="name" />
              <YAxis />
              <TooltipReact />
              <Bar dataKey="total" fill="#8884d8" barSize={40} />
            </BarChart>
          )}
        </div> */}
        <div className={Styles.header}>
          <div className={Styles.leftHeader}>
            <SearchbarFMCG
              value={searchNumber}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setSearchNumber(e.target.value);
                }
              }}
              searchWidth="237px"
              placeholder="Cari nomor"
            />

            <DropdownMui
              value={selectedType}
              setValue={setSelectedType}
              options={typeOptions || []}
              width={125}
              label="Semua Tipe"
            />

            <DropdownMui
              value={selectedStatus}
              setValue={setSelectedStatus}
              options={statusOptions || []}
              width={125}
            />

            {searchNumber || selectedType || selectedStatus ? (
              <Button
                placeHolder="Reset Filter"
                type="danger"
                onClick={() => {
                  setSearchNumber("");
                  setSelectedStatus("");
                  setSelectedType("");
                }}
              />
            ) : null}
          </div>
          <Button
            icon="Upload"
            placeHolder="Upload Nomor"
            onClick={() => {
              setShowModalUpload(true);
            }}
          />
        </div>

        <div className={Styles.content}>
          <Table
            data={templateData?.data || []}
            columns={templateData?.columns || []}
            withNumbering
            withPagination
            page={+page}
            setPage={setPage}
            limit={+limit}
            setLimit={setLimit}
            totalData={+allDataStock?.totalData || 0}
            totalPage={+allDataStock?.totalPage || 1}
            isAdminIndosat
            isLoading={loadingDataStock && !showDetail}
            onClickRow={(row) => {
              setShowDetail(row);
            }}
          />
        </div>
      </div>

      <ToastAdminIndosat
        showNotif={successUpdate}
        placeholder="Status berhasil diperbaharui"
      />

      <ToastAdminIndosat
        showNotif={errorUpdate?.isValidate}
        placeholder={errorUpdate?.message}
        isRed
      />

      <Modal onClose={() => {}} isOpen={showModalUpload || successCreate}>
        <AddStockModal
          handleDone={() => {
            setShowModalUpload(false);
            setSuccessCreate(false);
            setFiles(null);
          }}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleUploadStock}
          isLoading={isLoadingCreate}
          success={successCreate}
          error={error}
        />
      </Modal>

      <Modal
        onClose={() => {
          setShowDetail(null);
        }}
        isOpen={showDetail}
      >
        <DetailStockModal
          onClose={() => {
            setShowDetail(null);
          }}
          data={showDetail}
        />
      </Modal>
    </div>
  );
}

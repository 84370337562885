import * as types from "../../typeAdminIndosat";

const initialstate = {
  allDataDashboard: {},
  loadingDataDashboard: false,
  errorDashboard: {},
};

const DashboardAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.DASHBOARD_BEGIN: {
      return {
        ...state,
        loadingDataDashboard: true,
      };
    }

    case types.DASHBOARD_ERROR: {
      return {
        ...state,
        loadingDataDashboard: false,
        errorDashboard: action.payload,
      };
    }

    case types.FETCH_ALL_DASHBOARD: {
      return {
        ...state,
        loadingDataDashboard: false,
        allDataDashboard: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default DashboardAdminIndosatReducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import SearchbarFMCG from "../../FMCG/SearchbarFMCG/SearchbarFMCG";
import Table from "../Table";
import TabHeader from "../../Atom/TabHeader";
import { useHistory } from "react-router-dom";
import { useDebounce } from "../../../hooks/useDebounce";
import { getAllEmployeeAdminIndosat } from "../../../async/AdminIndosat/employee";
import AVA_DEFAULT from "../../../assets/images/ava-default.png";
// import { API_IMAGE } from "../../../redux/api";
import { capitalize } from "lodash";
export default function EmployeeAdminIndosat() {
  const history = useHistory();
  const [dataList, setDataList] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoadingHit, setIsLoadingHit] = useState(true);

  const debounceSearchQuery = useDebounce(searchValue, 300);

  const getDataEmployee = useCallback(async () => {
    setIsLoadingHit(true);
    try {
      const res = await getAllEmployeeAdminIndosat(
        page,
        limit,
        debounceSearchQuery
      );
      if (res.status === 200) {
        setDataList(res.data?.response);
      }
    } catch (error) {
      console.log("Error get data employee: ", error);
    } finally {
      setIsLoadingHit(false);
    }
  }, [debounceSearchQuery, limit, page]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    getDataEmployee();
  }, [getDataEmployee]);

  const templateDataPersonil = useMemo(() => {
    return {
      data: dataList?.data?.map((el) => ({
        id: el?.employeeId,
        name: capitalize(el?.name),
        profilePicture: el?.profilePicture ? el?.profilePicture : AVA_DEFAULT,
        leadImage: el?.supervisor?.profilePicture ?? AVA_DEFAULT,
        position: capitalize(el?.jobTitle),
        region: capitalize(el?.division),
        status: el?.status,
      })),
      columns: [
        {
          name: "name",
          title: <TabHeader text="Employee Name" />,
          renderData: (row) => (
            <div
              className={Styles.withProfilePic}
              onClick={() => {
                return history.push(`employee/${row?.id}`);
              }}
            >
              <img className={Styles.img} src={row.profilePicture} alt="" />
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "id",
          title: <TabHeader text="NRK" />,
        },

        {
          name: "position",
          title: <TabHeader text="Position" />,
        },

        {
          name: "region",
          title: <TabHeader text="Region" />,
        },

        {
          name: "leadImage",
          title: "Lead",
          renderData: (row) => (
            <img src={row.leadImage} className={Styles.img} alt="" />
          ),
        },

        {
          name: "status",
          title: "Status",
          renderData: (row) => (
            <div
              className={` ${Styles.status} ${
                row.status.toLowerCase() === "active"
                  ? Styles.active
                  : Styles.inActive
              }`}
            >
              <span>{capitalize(row.status)}</span>
            </div>
          ),
        },
      ],
    };
  }, [dataList?.data, history]);

  return (
    <div className={Styles.wrapperEmployeePage}>
      <div className={Styles.header}>
        <h1>Employee</h1>
        <SearchbarFMCG
          placeholder={"Search by name"}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className={Styles.table}>
        <Table
          data={templateDataPersonil.data}
          columns={templateDataPersonil.columns}
          withNumbering
          className={Styles.tableClass}
          withPagination
          page={+page}
          setPage={setPage}
          limit={+limit}
          setLimit={setLimit}
          totalData={dataList?.totalData || 0}
          totalPage={dataList?.totalPages || 0}
          isAdminIndosat
          isLoading={isLoadingHit}
        />
      </div>
    </div>
  );
}

import { useRef } from "react";
import Styles from "./styles.module.scss";
import Icon from "../../Icon";

const MonthPicker = ({
  value,
  setValue,
  typeDate,
  disabled,
  type,
  ...props
}) => {
  const dateRef = useRef();

  function formatDateMonth(date) {
    if (!date) {
      return "seluruh total";
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    return [year, month].join("-");
  }

  const handlePickerClick = () => {
    if (dateRef.current?.showPicker) {
      dateRef.current.showPicker();
    } else {
      console.warn("showPicker tidak didukung di browser ini.");
    }
  };

  return (
    <div className={Styles.container} onClick={handlePickerClick}>
      <div className={Styles.inputWrapper}>
        <div className={Styles.iconWrapper}>
          <Icon
            className={Styles.icon}
            color={"#1571DE"}
            icon="calendar"
            size={24}
          />
        </div>
        <input
          className={Styles.input}
          value={value ? formatDateMonth(value) : ""}
          onChange={(e) => setValue(new Date(e.target.value))}
          placeholder="Pilih tanggal"
          type="month"
          ref={dateRef}
        />
      </div>
    </div>
  );
};

export default MonthPicker;

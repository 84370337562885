/* eslint-disable no-unused-vars*/

import React from "react";
import { Helmet } from "react-helmet";
import { Grid, Container, Button } from "@mui/material";
import { useHistory } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import {
  AttendanceDetailExternal,
  EmployeeAttendanceDetailExternal,
} from "../../../components/External/Attendance/";
function IndosatAttendanceDetail() {
  const history = useHistory();

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attendance</title>
      </Helmet>
      <div className="">
        <Breadcrumb>
          <Button
            onClick={() => history.push("/admin-indosat/absence")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Attendance</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>
            Detail Attendance
          </span>
        </Breadcrumb>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <EmployeeAttendanceDetailExternal noOvertime/>
        </Grid>
      </Grid>
    </Container>
  );
}

export default IndosatAttendanceDetail;

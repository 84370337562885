import * as types from "../../typeAdminIndosat";

const initialstate = {
  allData: {
    data:[],
    pagination:{
      totalPage:1,
      totalData:0,
    }
  },
  loadingData: false,
  error: {},
};

const SalesAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.SALES_BEGIN: {
      return {
        ...state,
        loadingData: true,
      };
    }
    case types.SALES_ERROR: {
      return {
        ...state,
        loadingData: false,
        error: action.payload,
      };
    }

    case types.FETCH_ALL_SALES: {
      return {
        ...state,
        loadingData: false,
        allData: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default SalesAdminIndosatReducer;

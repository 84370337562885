import { useEffect, useMemo, useRef, useState } from "react";
import MonthPicker from "../../Atom/MonthPicker";
import Styles from "./styles.module.scss";
import Icon from "../../Icon";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as TooltipReact,
  CartesianGrid,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDashboard } from "../../../redux/actions/AdminIndosat/dashboardActions";
import Skeleton from "../../Atom/Skeleton";
import moment from "moment";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className={Styles.tooltipBox}>
        <span className={Styles.month}>Month: {label}</span>
        <div>
          {Object.entries(data).map(
            ([key, value]) =>
              key !== "name" && ( // Mengabaikan atribut 'name'
                <div key={key} style={{ marginBottom: "4px" }}>
                  <span>{key}:</span> {value}
                </div>
              )
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default function DashboardAdminIndosat() {
  const [chartWidth, setChartWidth] = useState(0);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");

  const getCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Tambahkan leading zero jika perlu
    return `${year}-${month}`; // Format YYYY-MM
  };

  function isValidDate(dateString) {
    const date = new Date(dateString); // Membuat objek Date dari input
    return !isNaN(date.getTime()); // Mengecek apakah timestamp valid
  }
  function calculateChange(num, numPrev) {
    // Ganti null dengan 0
    const number = num ?? 0;
    const numberPrev = numPrev ?? 0;

    // Gunakan epsilon untuk menghindari pembagian dengan nol
    const epsilon = 1e-10; // Nilai sangat kecil
    const effectivePrevSold = numberPrev === 0 ? epsilon : numberPrev;

    const difference = number - numberPrev;
    let percentageChange = (difference / effectivePrevSold) * 100;

    // Batasi maksimal hingga 100%
    if (percentageChange > 100) {
      percentageChange = 100;
    }

    return {
      status:
        difference > 0 ? "increase" : difference < 0 ? "decrease" : "increase",
      percentage: `${Math.round(percentageChange)}%`, // Tidak ada desimal
    };
  }

  function getYear(date) {
    return date.getFullYear(); // Mengambil tahun (4 digit)
  }

  function getMonthNumber(date) {
    return date.getMonth() + 1; // Menambahkan 1 karena getMonth() dimulai dari 0
  }

  const [month, setMonth] = useState(getCurrentMonth() || "");

  useEffect(() => {
    if (!isValidDate(month)) {
      setMonth(getCurrentMonth());
    }
  }, [month, getCurrentMonth, isValidDate]);
  // // dashboardAdminIndosat

  const { allDataDashboard, errorDashboard, loadingDataDashboard } =
    useSelector((state) => state.dashboardAdminIndosat);

  useEffect(() => {
    dispatch(
      fetchAllDashboard(
        token,
        isValidDate(month) ? getYear(new Date(month)) : getYear(new Date()),
        isValidDate(month)
          ? getMonthNumber(new Date(month))
          : getMonthNumber(new Date())
      )
    );
  }, [dispatch, month, token]);

  const totalDash = useMemo(() => {
    return [
      {
        title: "Total Transaksi",
        total: loadingDataDashboard ? (
          <Skeleton />
        ) : allDataDashboard?.data?.totalTransactions ? (
          parseFloat(allDataDashboard?.data?.totalTransactions?.toFixed(2))
        ) : (
          0
        ),
        icon: "cashier-machine",
        color: "#ABA9FF",
        background: "#e8e6fb",
        content: loadingDataDashboard ? (
          <Skeleton />
        ) : (
          <div className={Styles.percentage}>
            <div>
              <Icon
                icon={
                  calculateChange(
                    allDataDashboard?.data?.totalTransactions || 0,
                    allDataDashboard?.data?.totalPrevTransactions || 0
                  )?.status === "increase"
                    ? "ic-trending-up"
                    : "ic-trending-down"
                }
                size={24}
                color={
                  calculateChange(
                    allDataDashboard?.data?.totalTransactions || 0,
                    allDataDashboard?.data?.totalPrevTransactions || 0
                  )?.status === "increase"
                    ? "#00B69B"
                    : "#F93C65"
                }
              />
            </div>
            <span>
              <span
                className={`${
                  calculateChange(
                    allDataDashboard?.data?.totalTransactions || 0,
                    allDataDashboard?.data?.totalPrevTransactions || 0
                  )?.status === "decrease" && Styles.red
                }`}
              >
                {
                  calculateChange(
                    allDataDashboard?.data?.totalTransactions || 0,
                    allDataDashboard?.data?.totalPrevTransactions || 0
                  )?.percentage
                }
              </span>{" "}
              dari bulan lalu
            </span>
          </div>
        ),
      },
      {
        title: "Total Nomor Terjual",
        total: loadingDataDashboard ? (
          <Skeleton />
        ) : allDataDashboard?.data?.numberSold ? (
          parseFloat(allDataDashboard?.data?.numberSold?.toFixed(2))
        ) : (
          0
        ),
        icon: "sim-card",
        color: "#FEC53D",
        background: "#fff4cc",
        content: loadingDataDashboard ? (
          <Skeleton />
        ) : (
          <div className={Styles.percentage}>
            <div>
              <Icon
                icon={
                  calculateChange(
                    allDataDashboard?.data?.numberSold || 0,
                    allDataDashboard?.data?.numberPrevSold || 0
                  )?.status === "increase"
                    ? "ic-trending-up"
                    : "ic-trending-down"
                }
                size={24}
                color={
                  calculateChange(
                    allDataDashboard?.data?.numberSold || 0,
                    allDataDashboard?.data?.numberPrevSold || 0
                  )?.status === "increase"
                    ? "#00B69B"
                    : "#F93C65"
                }
              />
            </div>
            <span>
              <span
                className={`${
                  calculateChange(
                    allDataDashboard?.data?.numberSold || 0,
                    allDataDashboard?.data?.numberPrevSold || 0
                  )?.status === "decrease" && Styles.red
                }`}
              >
                {
                  calculateChange(
                    allDataDashboard?.data?.numberSold || 0,
                    allDataDashboard?.data?.numberPrevSold || 0
                  )?.percentage
                }
              </span>{" "}
              dari bulan lalu
            </span>
          </div>
        ),
      },
      {
        title: "Total Customer",
        total: loadingDataDashboard ? (
          <Skeleton />
        ) : allDataDashboard?.data?.store ? (
          parseFloat(allDataDashboard?.data?.store?.toFixed(2))
        ) : (
          0
        ),
        icon: "group",
        color: "#80DDFF",
        background: "#e6f7ff",
        content: loadingDataDashboard ? (
          <Skeleton />
        ) : (
          <div className={Styles.percentage}>
            <div>
              <Icon
                icon={
                  calculateChange(
                    allDataDashboard?.data?.store || 0,
                    allDataDashboard?.data?.storePrev || 0
                  )?.status === "increase"
                    ? "ic-trending-up"
                    : "ic-trending-down"
                }
                size={24}
                color={
                  calculateChange(
                    allDataDashboard?.data?.store || 0,
                    allDataDashboard?.data?.storePrev || 0
                  )?.status === "increase"
                    ? "#00B69B"
                    : "#F93C65"
                }
              />
            </div>
            <span>
              <span
                className={`${
                  calculateChange(
                    allDataDashboard?.data?.store || 0,
                    allDataDashboard?.data?.storePrev || 0
                  )?.status === "decrease" && Styles.red
                }`}
              >
                {
                  calculateChange(
                    allDataDashboard?.data?.store || 0,
                    allDataDashboard?.data?.storePrev || 0
                  )?.percentage
                }
              </span>{" "}
              dari bulan lalu
            </span>
          </div>
        ),
      },
      {
        title: "Avg. Nomor Terjual",
        total: loadingDataDashboard ? (
          <Skeleton />
        ) : allDataDashboard?.data?.avgNumberSold ? (
          parseFloat(allDataDashboard?.data?.avgNumberSold?.toFixed(2))
        ) : (
          0
        ),
        icon: "line-chart",
        color: "#4AD991",
        background: "#e8f5e9",
        content: loadingDataDashboard ? (
          <Skeleton />
        ) : (
          <div className={Styles.percentage}>
            <div>
              <Icon
                icon={
                  calculateChange(
                    allDataDashboard?.data?.avgNumberSold || 0,
                    allDataDashboard?.data?.avgNumberPrevSold || 0
                  )?.status === "increase"
                    ? "ic-trending-up"
                    : "ic-trending-down"
                }
                size={24}
                color={
                  calculateChange(
                    allDataDashboard?.data?.avgNumberSold || 0,
                    allDataDashboard?.data?.avgNumberPrevSold || 0
                  )?.status === "increase"
                    ? "#00B69B"
                    : "#F93C65"
                }
              />
            </div>
            <span>
              <span
                className={`${
                  calculateChange(
                    allDataDashboard?.data?.avgNumberSold || 0,
                    allDataDashboard?.data?.avgNumberPrevSold || 0
                  )?.status === "decrease" && Styles.red
                }`}
              >
                {
                  calculateChange(
                    allDataDashboard?.data?.avgNumberSold || 0,
                    allDataDashboard?.data?.avgNumberPrevSold || 0
                  )?.percentage
                }
              </span>{" "}
              dari bulan lalu
            </span>
          </div>
        ),
      },
    ];
  }, [calculateChange, allDataDashboard, loadingDataDashboard]);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const data = useMemo(() => {
    if (loadingDataDashboard) {
      return [
        { name: "Jan", total: 50 },
        { name: "Feb", total: 50 },
        { name: "Mar", total: 50 },
        { name: "Apr", total: 50 },
        { name: "May", total: 50 },
        { name: "Jun", total: 50 },
        { name: "Jul", total: 50 },
        { name: "Aug", total: 50 },
        { name: "Sep", total: 50 },
        { name: "Oct", total: 50 },
        { name: "Nov", total: 50 },
        { name: "Dec", total: 50 },
      ];
    } else {
      if (allDataDashboard?.data?.graph?.length > 0) {
        return allDataDashboard?.data?.graph?.map((el) => {
          return {
            total: el?.count || 0,
            name: el?.month
              ? moment()
                  .month(el?.month - 1)
                  .format("MMM")
              : "-",
          };
        });
      } else {
        return [
          { name: "Jan", total: 0 },
          { name: "Feb", total: 0 },
          { name: "Mar", total: 0 },
          { name: "Apr", total: 0 },
          { name: "May", total: 0 },
          { name: "Jun", total: 0 },
          { name: "Jul", total: 0 },
          { name: "Aug", total: 0 },
          { name: "Sep", total: 0 },
          { name: "Oct", total: 0 },
          { name: "Nov", total: 0 },
          { name: "Dec", total: 0 },
        ];
      }
    }
  }, [allDataDashboard, loadingDataDashboard]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.header}>
          <span>Dashboard</span>
          <div>
            <MonthPicker value={month} setValue={setMonth} typeDate={"month"} />
          </div>
        </div>

        <div className={Styles.cardBox}>
          {totalDash?.map((el, idx) => {
            return (
              <div key={idx} className={Styles.card}>
                <div className={Styles.top}>
                  <div>
                    <span>{el?.title}</span>
                    <span>
                      {el?.total}
                      {!loadingDataDashboard &&
                      el?.title === "Avg. Nomor Terjual" ? (
                        <span>/transaksi</span>
                      ) : null}
                    </span>
                  </div>
                  <div
                    style={{
                      backgroundColor: el?.background,
                    }}
                  >
                    <Icon icon={el?.icon} color={el?.color} size={20} />
                  </div>
                </div>

                <div className={Styles.bottom}>{el?.content}</div>
              </div>
            );
          })}
        </div>

        <div className={Styles.chartBox}>
          <span>Jumlah Penjualan Nomor</span>
          <div ref={containerRef}>
            {chartWidth > 0 && (
              <BarChart
                width={chartWidth}
                height={355}
                data={data}
                margin={{ top: 16, right: 16, left: 0, bottom: 16 }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis dataKey="name" />
                <YAxis />
                {!loadingDataDashboard && (
                  <TooltipReact
                    content={<CustomTooltip />}
                    cursor={{ fill: "#eeeeee" }}
                  />
                )}

                <Bar
                  dataKey="total"
                  fill={loadingDataDashboard ? "#cdd2db" : "#1571DE"}
                  barSize={40}
                  radius={[4, 4, 0, 0]}
                  className={loadingDataDashboard ? Styles["barLoading"] : ""}
                />
              </BarChart>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import ExcelJS from "exceljs";
import UploadCard from "../../../Atom/UploadCard";
import Button from "../../../Button";
import Icon from "../../../Icon";
import Styles from "./styles.module.scss";
import excelTemplate from './template.xlsx'

export default function AddStockModal({
  handleDone = () => { },
  handleSubmit = () => { },
  files = null,
  setFiles = () => { },
  isLoading = false,
  success = false,
  error = "",
  isAssign = false
}) {
  const exportExempleFileExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    // Menambahkan 3 baris kosong untuk memulai header di baris ke-4
    sheet.addRow([]);
    sheet.addRow([]);
    sheet.addRow([]);

    // Menambahkan header di baris ke-4
    sheet.addRow(["No", "No MSISDN", "No ICCID", "Tipe Kartu", "Harga"]);

    // Menyesuaikan style kolom secara manual
    const headerRow = sheet.getRow(4); // Ambil baris header
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
    });

    // Menyesuaikan lebar kolom
    sheet.columns = [
      { width: 10 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "TEMPLATE STOCK.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return success ? (
    <div className={Styles.succesContainer}>
      <div>
        <Icon icon={"circle-check"} size={100} color={"#5AAD70"} />
      </div>
      <span>{isAssign ? 'Kartu berhasil diassign' : 'Stock berhasil ditambahkan'}</span>
      <div>
        <Button placeHolder="Kembali" onClick={handleDone} />
      </div>
    </div>
  ) : (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>{isAssign ? 'Assign Kartu' : 'Upload Kartu'}</span>
        <div>
          <Icon icon={"upload-2"} color={"#0A0A0A"} size={24} />
        </div>
      </div>

      <div className={Styles.content}>
        <div className={Styles.top}>
          <span>
            {
              isAssign
                ?
                'Silahkan gunakan template berikut untuk melakukan assign kartu kepada para promotor'
                :
                'Silahkan gunakan template berikut untuk menambahkan data nomor'
            }
          </span>
          {
            isAssign
              ?
              <a
                href={excelTemplate}
                download="ASSIGN_TEMPLATE.xlsx"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <div className={Styles.div}>
                  <div>
                    <div className={Styles.imagesExcel}>
                      <Icon icon={"excel"} size={24} />
                    </div>
                    <span>{isAssign ? 'Template Assign Kartu' : 'Template Upload Nomor'}</span>
                  </div>
                  <div>
                    <Icon icon={"arrow-down-tray"} size={16} color={"#2C5364"} />
                  </div>
                </div>
              </a>
              :
              <div onClick={exportExempleFileExcel}>
                <div>
                  <div className={Styles.imagesExcel}>
                    <Icon icon={"excel"} size={24} />
                  </div>
                  <span>{isAssign ? 'Template Assign Kartu' : 'Template Upload Nomor'}</span>
                </div>
                <div>
                  <Icon icon={"arrow-down-tray"} size={16} color={"#2C5364"} />
                </div>
              </div>
          }


        </div>

        <UploadCard files={files} setFiles={setFiles} />
      </div>

      <div className={Styles.buttonWrapper}>
        {error && <span>*{error}</span>}
        <div className={Styles.button}>
          <div>
            <Button
              placeHolder="Batal"
              type="primary-outlined"
              onClick={handleDone}
            />
          </div>
          <div>
            <Button
              placeHolder="Upload file"
              onClick={isLoading || !files ? null : handleSubmit}
              isLoading={isLoading}
              disabled={isLoading || !files}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

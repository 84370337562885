/*eslint-disable no-unused-vars*/

import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdminIndosatSalesLayout } from "../../../components/IndosatAdmin";

export default function AdminIndosatSales() {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Sales</title>
      </Helmet>
      <AdminIndosatSalesLayout/>
      {/* <PawnDetailAdminMWSLayout data={loanDataById} dataById={ pawn } token={ token } id={ id}/> */}
    </div>
  );
}

import React from "react";
import Styles from "./style.module.scss";
import Modal from "../index";

export default function ModalWithIcon({ isOpen, onClose, icon, children }) {
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={Styles.container}>
          <div className={Styles.headerWrapper}>
            <div className={Styles.header}>{icon}</div>
          </div>
          <div className={Styles.body}>{children}</div>
        </div>
      </Modal>
    </div>
  );
}

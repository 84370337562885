import React from "react";
import "./Maintenance.css";
import MAINTENANCE_IMAGE from "../../assets/images/UnderMaintenance.png";
export default function UnderMaintenance({ isCenter = false }) {
  return (
    <div
      className={
        isCenter ? "maintenance-container-center" : "maintenance-container"
      }
    >
      <div className="maintenance-main">
        <div className="maitenance-image">
          <img src={MAINTENANCE_IMAGE} alt="" />
        </div>
        <div className="maintenance-text">
          <div className="maintenance-label">
            <span>Ooops!</span>
          </div>
          <div className="maintenance-text-main">
            <span>Sorry page is under maintenance</span>
          </div>
          <div className="maintenance-text-last">
            <span>
              Sorry for now this page is still under maintenance, please use
              some time in the future, we will display this page if it is fixed.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { fetchDetailEBP } from "../../../../redux/actions/Business/ebpBusinessActions";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { LayoutDetailEBP } from "../../../../components/Business/_ebp";

export default function DetailEBP() {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const { id } = useParams();
  const { detailDataEBP, loadingDataEBP } = useSelector(
    (state) => state.ebpBusiness
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const typeDetail = useSelector((state) => location?.state?.typeDetail);

  useEffect(() => {
    dispatch(fetchDetailEBP(token, id));
  }, [token, id, dispatch]);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Detail EBP</title>
        </Helmet>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumb>
            <Button
              onClick={() => {
                if (typeDetail === "KKB") {
                  history.push("/admin-business/ebp-kkb");
                } else if (typeDetail === "EDUCATION") {
                  history.push("/admin-business/ebp-education");
                }
              }}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                {typeDetail === "KKB"
                  ? "KKB"
                  : typeDetail === "EDUCATION"
                  ? "Biaya Pendidikan"
                  : ""}
              </span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              Submitter Detail
            </span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <LayoutDetailEBP
              data={detailDataEBP}
              loading={loadingDataEBP}
              typeDetail={typeDetail}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

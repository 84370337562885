import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

import SearchbarFMCG from "../../FMCG/SearchbarFMCG/SearchbarFMCG";
import {
  Avatar,
  Button,
  Dialog,
  Menu,
  MenuItem,
  Skeleton,
  styled,
  TextField,
} from "@mui/material";
import CustomTablePagination from "../../_pagination/CustomTablePagination";
import DropdownMui from "../../DropdownMUI";
import ButtonCustom from "../../Button";
import Modal from "../../Modal";
import CloseIcon from "@mui/icons-material/Close";
import ModalWithIcon from "../../Modal/ModalWithIcon";
import Icon from "../../Icon";
import SUCCESS_GIF from "../../../assets/gif/success-payment.gif";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { fetchAllSales } from "../../../redux/actions/AdminIndosat/salesActions";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  _acceptCashPayment,
  _acceptTransferPayment,
  _resetPayment,
  _selectPayment,
} from "../../../async/AdminIndosat";
import UploadCard from "../../Atom/UploadCard";
import EMPTY_HANDLER from "../../../assets/images/pana.png";
import { useHistory } from "react-router-dom";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const statusOptions = [
  "Menunggu Konfirmasi",
  "Menunggu Jatuh Tempo",
  "Jatuh Tempo",
  "Diterima",
];
const paymentOptions = ["Tunai", "Transfer", "Tempo"];

function getDateDifferenceInDays(date2) {
  const d1 = new Date();
  const d2 = new Date(date2);

  if (isNaN(d1) || isNaN(d2)) {
    throw new Error("Invalid date format. Please provide a valid date.");
  }

  const differenceMs = d2 - d1;
  const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

  return Math.floor(differenceDays);
}
export default function AdminIndosatSales() {
  const history = useHistory();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [changePaymentMethodModal, setChangePaymentMethodModal] =
    useState(false);
  const [successPaymentModal, setSuccessPaymentModal] = useState(false);
  const { allData: dataSales, loadingData } = useSelector(
    (state) => state.salesAdminIndosat
  );
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState(null);
  const debouncedSearch = useDebounce(search, 500);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const statusToSend = useMemo(() => {
    if (selectedStatus === "") {
      return "";
    }
    if (selectedStatus === "Menunggu Konfirmasi") {
      return "PENDING";
    } else if (selectedStatus === "Menunggu Jatuh Tempo") {
      return "WAITING-DUE-DATE";
    } else if (selectedStatus === "Jatuh Tempo") {
      return "DUE-DATE";
    } else {
      return "APPROVED";
    }
  }, [selectedStatus]);

  useEffect(() => {
    setPage(1);
  }, [selectedStatus, selectedPayment, dateFilter]);

  useEffect(() => {
    dispatch(
      fetchAllSales(
        `?page=${page}&limit=${limit}&status=${statusToSend}&date=${
          dateFilter === null ? "" : dateFilter
        }&search=${search}&paymentMethod=${
          selectedPayment === "Tunai"
            ? "CASH"
            : selectedPayment === "Transfer"
            ? "TRANSFER"
            : selectedPayment === "Tempo"
            ? "TEMPO"
            : ""
        }`
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    limit,
    page,
    selectedPayment,
    statusToSend,
    debouncedSearch,
    dateFilter,
    triggerFetch,
  ]);
  const handleSubmitPayment = async (id, file) => {
    try {
      if (id) {
        console.log(id, "1");
        await _acceptTransferPayment(id, file);
      } else if (openConfirmationModal.method === "CASH") {
        await _acceptCashPayment(openConfirmationModal._id);
      } else {
        await _acceptTransferPayment(openConfirmationModal._id);
      }
      setSuccessPaymentModal(true);
      setOpenConfirmationModal(false);
      setOpenUploadModal(false);
      dispatch(
        fetchAllSales(
          `?page=${page}&limit=${limit}&status=${statusToSend}&date=${
            dateFilter === null ? "" : dateFilter
          }&search=${search}&paymentMethod=${
            selectedPayment === "Tunai"
              ? "CASH"
              : selectedPayment === "Transfer"
              ? "TRANSFER"
              : selectedPayment === "Tempo"
              ? "TEMPO"
              : ""
          }`
        )
      );
    } catch (error) {
      setSuccessPaymentModal(false);
      setOpenConfirmationModal(false);
    }
  };

  const handleSelectPaymentMethod = async (id, paymentMethod) => {
    try {
      await _selectPayment(id, paymentMethod);
      setTriggerFetch(!triggerFetch);
    } catch (error) {
      console.log(error);
    }
  };
  const handleResetPaymentMethod = async (id) => {
    try {
      await _resetPayment(id);
      setChangePaymentMethodModal(false);
      setTriggerFetch(!triggerFetch);
    } catch (error) {
      console.log(error);
      // setChangePaymentMethodModal(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Enables smooth scrolling
    });
  }, [page]);
  

  return (
    <div className={Styles.container}>
      <div className={Styles.filterWrapper}>
        <SearchbarFMCG
          placeholder={"Cari nama promotor/toko"}
          searchWidth={235}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <DropdownMui
          value={selectedStatus}
          setValue={setSelectedStatus}
          options={statusOptions}
          width={150}
          label="Semua Status"
        />
        <DropdownMui
          value={selectedPayment}
          setValue={setSelectedPayment}
          options={paymentOptions}
          width={170}
          label="Semua Tipe Pembayaran"
        />
        {(selectedPayment || search || selectedStatus || dateFilter) && (
          <ButtonCustom
            type="danger"
            placeHolder="Reset Filter"
            onClick={() => {
              setSelectedStatus("");
              setSelectedPayment("");
              setSearch("");
              setDateFilter(null);
            }}
          />
        )}
        <div className={Styles.dateFilter}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={Styles.RangeDate}>
              <DatePicker
                value={dateFilter}
                views={["year", "month", "day"]}
                onChange={(e) => setDateFilter(e)}
                format="DD-MM-YYYY"
                required={false}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    sx={{ background: "var(--main-bg)", width: 150 }}
                    size="small"
                    required={false}
                    placeholder="Semua Tanggal"
                  />
                )}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>
      <div className={Styles.paginationWrapper}>
        <CustomTablePagination
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          totalPage={dataSales.pagination.totalPage}
          totalData={dataSales.pagination.totalData}
          className={Styles.pagination}
          isAdminIndosat
        />
      </div>
      <div className={Styles.cardWrapper}>
        {dataSales.data.length === 0 && !loadingData && (
          <div className={Styles.emptyHandlerBox}>
            <div className={Styles.images}>
              <img src={EMPTY_HANDLER} alt="" />
            </div>
            <span>Data Belum Tersedia</span>
            <span>Belum ada data yang ditambahkan ke dalam daftar</span>
          </div>
        )}
        {loadingData
          ? Array.from({ length: limit }).map((el, idx) => (
              <Skeleton variant="rounded" height={188} animation="wave" />
            ))
          : dataSales.data.map((el, idx) => {
              const checkStatus = () => {
                if (el.statusTransaction === "APPROVED") {
                  return "Diterima";
                }
                if (el.isTempo) {
                  if (getDateDifferenceInDays(el.tempoAt) <= 0) {
                    return "Jatuh Tempo";
                  } else {
                    return "Menunggu Jatuh Tempo";
                  }
                } else {
                  return "Menunggu Konfirmasi";
                }
              };

              const status = checkStatus();
              return (
                <div className={Styles.card} key={idx}>
                  <div className={Styles.cardHeader}>
                    <div className={Styles.cardInfo}>
                      <p className={Styles.cardID}>{el.code} </p>
                      <span className={Styles.divider}>/</span>
                      <div className={Styles.cardPromotor}>
                        <p>Promotor :</p>
                        <div className={Styles.avatarPromotor}>
                          <Avatar
                            src={el.promotor.profilePicture}
                            sx={{ width: 24, height: 24 }}
                          >
                            {el.promotor.name[0]}
                          </Avatar>
                          <p>{el.promotor.name}</p>
                        </div>
                      </div>
                      <span className={Styles.divider}>/</span>
                      <div className={Styles.cardDate}>
                        <i className="bx bx-calendar"></i>
                        <p>
                          {moment(el.createdAt).format("DD MMM YYYY, HH:mm")}
                        </p>
                      </div>
                    </div>
                    <div className={Styles.paymentStatusWrapper}>
                      <p>Status Bayar : </p>
                      <div
                        className={Styles.paymentStatus}
                        is-waiting={(
                          status === "Menunggu Konfirmasi"
                        ).toString()}
                        is-overdue={(status === "Jatuh Tempo").toString()}
                        is-accepted={(status === "Diterima").toString()}
                        is-due={(status === "Menunggu Jatuh Tempo").toString()}
                      >
                        <p>{status}</p>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.cardBody}>
                    <div className={Styles.labelWrapper}>
                      <p className={Styles.label}>Nama Toko</p>
                      <p className={Styles.value}>{el?.store?.name}</p>
                    </div>
                    <div className={Styles.labelWrapper}>
                      <p className={Styles.label}>Pemilik Toko</p>
                      <p className={Styles.value}>{el?.store?.ownerName}</p>
                    </div>
                    <div className={Styles.labelWrapper}>
                      <p className={Styles.label}>Nomor Telepon</p>
                      <div className={Styles.phoneNumber}>
                        <p className={Styles.value}>{el?.store?.phoneNumber}</p>
                        <div className={Styles.copy}>
                          <i className="bx bxs-copy"></i>
                          <p>Salin</p>
                        </div>
                      </div>
                    </div>
                    <div className={Styles.labelWrapper}>
                      <p className={Styles.label}>Jumlah Pembelian</p>
                      <p className={Styles.value}>
                        {el.items.length} <span>Kartu</span>
                      </p>
                    </div>
                    <div className={Styles.labelWrapper}>
                      <p className={Styles.label}>Tipe Pembayaran</p>
                      {el.isTempo && (
                        <p className={Styles.value}>
                          Tempo{" "}
                          <span>
                            - {moment(el.tempoAt).format("DD MMM YYYY")}
                          </span>
                        </p>
                      )}

                      {el.isTempo && el.statusTransaction === "PENDING" && (
                        <p className={Styles.value}>
                          <span
                            className={Styles.dueDate}
                            is-overdue={(
                              new Date(el.tempoAt) < new Date()
                            ).toString()}
                          >
                            <Icon icon={"clock-forward"} size={16} />
                            <span>
                              {getDateDifferenceInDays(el.tempoAt)} hari
                            </span>
                          </span>
                        </p>
                      )}

                      {el.method === "CASH" && (
                        <p className={Styles.value}>
                          Tunai{" "}
                          {el.isTempo && el.statusTransaction === "PENDING" && (
                            <span
                              onClick={() => setChangePaymentMethodModal(el)}
                              className={Styles.link}
                            >
                              Ganti Cara Bayar
                            </span>
                          )}
                        </p>
                      )}
                      {el.method === "TRANSFER" && (
                        <p className={Styles.value}>
                          Transfer -{" "}
                          {el.isTempo && el.statusTransaction === "PENDING" ? (
                            <span
                              onClick={() => setChangePaymentMethodModal(el)}
                              className={Styles.link}
                            >
                              Ganti Cara Bayar
                            </span>
                          ) : (
                            <span
                              onClick={() =>
                                setOpenReceiptModal(el.files[0].url)
                              }
                              className={Styles.link}
                            >
                              Lihat Bukti
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={Styles.cardFooter}>
                    <div className={Styles.priceWrapper}>
                      <i className="bx bxs-info-circle"></i>
                      <p className={Styles.label}>Total Harga</p>
                      <p className={Styles.price}>
                        Rp{el?.paid?.toLocaleString("id")}
                      </p>
                    </div>
                    <div className={Styles.buttonWrapper}>
                      <ButtonCustom
                        type="primary-outlined"
                        placeHolder="Lihat Detail"
                        onClick={() =>
                          history.push(
                            `/admin-indosat/sales/${el?._id}`,
                            {
                              data:el,
                            }
                          )
                        }
                      />
                      {el.method !== "TEMPO" ? (
                        <ButtonCustom
                          placeHolder="Terima Pembayaran"
                          onClick={() => {
                            el.isTempo && el.method === "TRANSFER"
                              ? setOpenUploadModal(el)
                              : setOpenConfirmationModal(el);
                          }}
                          disabled={el.statusTransaction === "APPROVED"}
                        />
                      ) : (
                        <ButtonDropdown
                          handleSelectPaymentMethod={handleSelectPaymentMethod}
                          data={el}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <div className={Styles.paginationWrapper}>
        <CustomTablePagination
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          totalPage={dataSales.pagination.totalPage}
          totalData={dataSales.pagination.totalData}
          className={Styles.pagination}
          isAdminIndosat
        />
      </div>

      <Dialog
        open={openReceiptModal}
        onClose={() => setOpenReceiptModal(false)}
        fullWidth={false}
        maxWidth="xl"
      >
        <div className={Styles.receiptModalContainer}>
          <div className={Styles.header}>
            <p>Bukti transfer</p>
            <CloseIcon
              sx={{ fontSize: 24 }}
              onClick={() => setOpenReceiptModal(false)}
            />
          </div>
          <img src={openReceiptModal} alt="" />
        </div>
      </Dialog>

      <ModalWithIcon
        isOpen={openConfirmationModal}
        icon={<Icon icon={"cashier-machine"} size={60} color={"#8280FF"} />}
        onClose={() => setOpenConfirmationModal(false)}
      >
        <div className={Styles.confirmPaymentModal}>
          <div className={Styles.modalBody}>
            <p>Terima Pembayaran</p>
            <p>Apakah kamu yakin ingin menerima hasil penjualan sebesar</p>
            <p>Rp{openConfirmationModal?.paid?.toLocaleString("id")}</p>
          </div>
          <div className={Styles.modalAction}>
            <ButtonCustom
              type="primary-outlined"
              placeHolder="Batal"
              onClick={() => setOpenConfirmationModal(false)}
            />
            <ButtonCustom
              type="primary"
              placeHolder="Terima"
              onClick={handleSubmitPayment}
            />
          </div>
        </div>
      </ModalWithIcon>

      <ModalWithIcon
        isOpen={changePaymentMethodModal}
        icon={<p className={Styles.changePaymentModalIcon}>?</p>}
        onClose={() => setChangePaymentMethodModal(false)}
      >
        <div className={Styles.changePaymentModal}>
          <div className={Styles.modalBody}>
            <p>Ganti Cara Bayar</p>
            <p>Apakah kamu ingin mengganti cara bayar transaksi ini?</p>
          </div>
          <div className={Styles.modalAction}>
            <ButtonCustom
              type="primary-outlined"
              placeHolder="Batal"
              onClick={() => {
                setChangePaymentMethodModal(false);
              }}
            />
            <ButtonCustom
              type="primary"
              placeHolder="Ya, ganti"
              onClick={() => {
                handleResetPaymentMethod(changePaymentMethodModal._id);
              }}
            />
          </div>
        </div>
      </ModalWithIcon>

      <Modal
        isOpen={successPaymentModal}
        onClose={() => setSuccessPaymentModal(false)}
      >
        <div className={Styles.successPaymentModal}>
          <img src={SUCCESS_GIF} alt="" />
          <p>Hasil Penjualan Diterima</p>
          <ButtonCustom
            placeHolder="Tutup"
            onClick={() => setSuccessPaymentModal(false)}
          />
        </div>
      </Modal>

      <Modal isOpen={openUploadModal} onClose={() => {}}>
        <div className={Styles.uploadModalContainer}>
          <div className={Styles.header}>
            <span>Upload Bukti Transfer</span>
            <div>
              <Icon icon={"upload-2"} color={"#0A0A0A"} size={24} />
            </div>
          </div>
          <div className={Styles.modalBody}>
            <UploadCard files={file} setFiles={setFile} imageOnly />
          </div>
          <div className={Styles.modalAction}>
            <ButtonCustom
              type="primary-outlined"
              placeHolder="Cancel"
              onClick={() => {
                setOpenUploadModal(false);
                setFile(null);
              }}
            />
            <ButtonCustom
              type="primary"
              placeHolder="Terima Pembayaran"
              disabled={!file}
              onClick={() => {
                handleSubmitPayment(openUploadModal._id, file);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

const ButtonDropdown = ({ handleSelectPaymentMethod, data }) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = Math.random();

  return (
    <div className={Styles.buttonWithIcon}>
      <Button
        id={id}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
      >
        Pilih Metode Pembayaran
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ width: "230px !important" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleSelectPaymentMethod(data._id, "CASH");
            // setOpenConfirmationModal(el);
          }}
        >
          Tunai
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleSelectPaymentMethod(data._id, "TRANSFER");
            // setOpenUploadModal(el);
          }}
        >
          Transfer
        </MenuItem>
      </Menu>
    </div>
  );
};

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import Scrollbar from '../SuperAdmin/_user/TableUsers/Scrollbar';
import Styles from './customTable.module.css';
import { visuallyHidden } from '@mui/utils';
import CustomTablePagination from '../_pagination/CustomTablePagination';

function CustomTable({
    bodyData,
    tableHead,
    customEmpty = () => (
        <div className={Styles.CenterEmptyDataWrapper}>
            <div className={Styles.EmptyDataWrapper}>
                <span>Data Kosong</span>
            </div>
        </div>
    ), //component
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalPage,
    maxHeight = 'auto',
}) {
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead
                sx={{
                    '& tr': {
                        backgroundColor: '#F0F0F0',
                    },
                }}>
                <TableRow>
                    {tableHead?.map((headCell, index) => (
                        <TableCell
                            key={headCell.id + index}
                            align={headCell?.alignRight ? "right" : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <div>
            <div className={Styles.TableWrapper}>
                <Scrollbar>
                    <TableContainer className={Styles.TableContainer} sx={{ maxHeight: maxHeight }}>
                        <Table id="emp-table" className={Styles.Table}>
                            <colgroup>
                                {tableHead?.map((cHead, index) => (
                                    <col key={`col_group_${index}`} style={{ width: cHead?.width || 'auto' }} />
                                ))}
                            </colgroup>
                            <EnhancedTableHead
                                numSelected={bodyData?.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                values={bodyData}
                            />
                            {bodyData?.length > 0 && (
                                <TableBody>
                                    {stableSort(bodyData || [], getComparator(order, orderBy)).map((data, ind) => {
                                        return (
                                            <TableRow hover key={'tablebody' + ind} tabIndex={-1}>
                                                {tableHead?.map((h, i) => {
                                                    if (h?.renderData) {
                                                        return (
                                                            <TableCell align={h?.alignRight ?'right' : 'left'} key={`cell_${i}`}>
                                                                {h?.renderData(data)}
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        const cell = data[h?.id];
                                                        return <TableCell key={`cell_${i}`} align={h?.alignRight ?'right' : 'left'}>{cell}</TableCell>;
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                {bodyData?.length <= 0 ? customEmpty() : ''}
                <CustomTablePagination
                    limit={rowsPerPage}
                    setLimit={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                    totalPage={totalPage || 1}
                />
            </div>
        </div>
    );
}

export default CustomTable;

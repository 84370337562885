import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/setting-logo.png';
import { fetchAccountNumbers } from '../../../redux/actions/AdminIndosat/accountNumberActions';
import { fetchBanks } from '../../../redux/actions/AdminIndosat/bankActions';
import ToastAdminIndosat from '../../Atom/ToastAdminIndosat';
import Button from '../../Button';
import Icon from '../../Icon';
import AddAccountNumberModal from '../../_modal/AddAccountNumberModal';
import DeleteConfirmationModal from '../../_modal/DeleteConfirmationModal/ModalLoanSA';
import Table from '../Table';
import Styles from './style.module.scss';

export default function SettingLayout() {
  // eslint-disable-next-line no-unused-vars
  const { allDataAccountNumbers, errorAccountNumbers, loadingDataAccountNumbers } = useSelector(
    (state) => state.accountNumberAdminIndosat
  )
  const { allDataBanks, errorBanks, loadingDataBanks } = useSelector(
    (state) => state.banksAdminIndosat
  )

  const [showAdd, setShowAdd] = useState(false)
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);

  const [successText, setSuccessText] = useState('');

  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(
      fetchBanks()
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fetchAccountNumbers()
    );
  }, [dispatch]);
  console.log(dataToEdit, 'dataToEdit')


  const data = useMemo(() => {
    return allDataAccountNumbers.map(obj => {
      return {
        ...obj,
        bankName: obj?.bankName,
        accountNumber: obj?.accountNumber,
        accountName: obj?.accountName,
      }
    })
  }, [allDataAccountNumbers])

  const TABLE_HEAD = [

    { name: "bankName", title: "Nama Bank", align: "left", width: "30%" },
    { name: "accountNumber", title: "Nomor Rekening", align: "left", width: "30%" },
    {
      name: "accountName",
      title: "Nama Pemegang Rekening",
      align: "left",
      width: "35%",
    },
    {
      name: 'actions',
      title: '',
      align: 'right',
      width: '5%',
      renderData: (row) => (
        <div className={Styles.rowActions}>
          <button onClick={() => setDataToEdit(row)} className={Styles.edit}>
            <Icon icon={'pencil'} color={'#1571DE'} size={20} />
          </button>
          <button onClick={() => setDataToDelete(row)} className={Styles.delete}>
            <Icon icon={'delete'} color={'#D42701'} size={20} />
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (successText) {
      setTimeout(() => setSuccessText(''), 3000)
    }
  }, [successText])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <h3>Pengaturan</h3>
          <span>Lakukan pengaturan kamu di halaman ini</span>

          <div className={`${Styles.dot} ${Styles.d1}`} />
          <div className={`${Styles.dot} ${Styles.d2}`} />
          <div className={`${Styles.dot} ${Styles.d3}`} />
        </div>

        <div className={Styles.right}>
          <img src={logo} alt='' />
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.actions}>
          <h4>Daftar Rekening</h4>
          <Button
            icon="control-point"
            placeHolder="Tambah Rekening"
            onClick={() => {
              setShowAdd(true);
            }}
          />
        </div>
        <div className={Styles.main}>
          <Table
            columns={TABLE_HEAD}
            data={data}
            withNumbering
            isAdminIndosat
            isLoading={loadingDataAccountNumbers}
          />
        </div>
      </div>
      <AddAccountNumberModal
        isOpen={showAdd}
        onClose={() => setShowAdd(false)}
        allDataBanks={allDataBanks}
        loadingDataBanks={loadingDataBanks}
        errorBanks={errorBanks}
        setSuccess={setSuccessText}
      />
      <AddAccountNumberModal
        isOpen={!!dataToEdit}
        onClose={() => setDataToEdit(null)}
        allDataBanks={allDataBanks}
        loadingDataBanks={loadingDataBanks}
        errorBanks={errorBanks}
        setSuccess={setSuccessText}
        isEdit
        defaultData={dataToEdit}
      />
      <ToastAdminIndosat
        showNotif={successText}
        placeholder={successText}
      />
      {
        dataToDelete
        &&
        <DeleteConfirmationModal
          label={'Hapus Rekening'}
          description="Apakah anda yakin ingin menghapus rekening ini?"
          onClose={() => setDataToDelete(null)}
          dataToDelete={dataToDelete}
          setSuccess={setSuccessText}
        />

      }
    </div>
  )
}
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../Modal";
import Styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import DropdownMui from "../../../DropdownMUI";
import SearchbarFMCG from "../../../FMCG/SearchbarFMCG/SearchbarFMCG";
import Table from "../../Table";
import TabHeader from "../../../Atom/TabHeader";
import DetailStockModal from "../../NumberListIndosat/DetailStockModal";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getDataModalDetailBatchPromotorAdminIndosat } from "../../../../async/AdminIndosat/promotor";
import { capitalize } from "lodash";
import moment from "moment";

export default function ModalDetailBatchAdminIndosat({
  onClose,
  isOpen,
  id = null,
  dataProps,
}) {
  const [sortType, setSortType] = useState("");
  const [sortStatus, setSortStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const debounceSearchQuery = useDebounce(searchValue, 500);
  const [modalCardDetail, setModalCardDetail] = useState(false);
  const [isLoadingHit, setIsLoadingHit] = useState(true);

  const [dataListBatch, setDataListBatch] = useState();
  const fetchAllDataBatch = useCallback(async () => {
    setIsLoadingHit(true);
    try {
      const res = await getDataModalDetailBatchPromotorAdminIndosat(
        id,
        page,
        limit,
        debounceSearchQuery,
        sortType?.toUpperCase(),
        sortStatus === "Tersedia"
          ? "AVAILABLE"
          : sortStatus === "Terjual"
          ? "SOLD"
          : sortStatus === "Void"
          ? "VOID"
          : sortStatus === "Dikembalikan"
          ? "RETURN"
          : ""
      );
      if (res.status === 200) {
        setDataListBatch(res?.data?.response);
      }
    } catch (error) {
      console.log("Error get all batch", error);
    } finally {
      setIsLoadingHit(false);
    }
  }, [debounceSearchQuery, limit, page, sortStatus, sortType, id]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    fetchAllDataBatch();
  }, [fetchAllDataBatch]);

  const templateDataPersonil = useMemo(() => {
    return {
      data:
        dataListBatch?.data?.map((c) => ({
          id: c?._id || 0,
          MSISDN: c?.msisdn || "-",
          ICCID: c?.iccid || "-",
          typeCard: capitalize(c?.type) || "-",
          price: c?.price?.toLocaleString("id") || "-",
          status: c?.status?.toLowerCase() || "-",
        })) || [],
      columns: [
        {
          width: "23%",
          name: "MSISDN",
          title: <TabHeader text="No MSISDN" />,
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setModalCardDetail(row)}
            >
              {row?.MSISDN}
            </span>
          ),
        },
        {
          width: "32%",
          name: "ICCID",
          title: <TabHeader text="No ICCID" />,
        },

        {
          width: "20%",
          name: "typeCard",
          title: <TabHeader text="Tipe Kartu" />,
        },

        {
          name: "price",
          title: <TabHeader text="Harga" />,
          renderData: (row) => (
            <div style={{ width: "100px" }}>{`Rp ${row?.price.toLocaleString(
              "id"
            )}`}</div>
          ),
        },

        {
          name: "status",
          title: <TabHeader text="Status" />,
          renderData: (row) => (
            <div className={Styles.status} status={row?.status}>
              <span>
                {row?.status === "available"
                  ? "Tersedia"
                  : row?.status === "return"
                  ? "Dikembalikan"
                  : row?.status === "sold"
                  ? "Terjual"
                  : "Void"}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataListBatch?.data]);

  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.wrapperModalDetailBatchAdminIndosat}>
        <div className={Styles.header}>
          <div className={Styles.between}>
            <h3>Detail Batch</h3>
            <CloseIcon className={Styles.closeIcon} onClick={onClose} />
          </div>
          <div className={Styles.cardWrapper}>
            <div className={Styles.outCard}>
              <h1>{dataProps?.batch}</h1>
              <span>{moment(dataProps?.dateAssign).format("LL")}</span>
            </div>
            <div className={Styles.card}>
              <span>Jumlah Kartu Di Assign</span>
              <strong>{dataProps?.cardInAssign?.toLocaleString("id")}</strong>
            </div>
            <div className={Styles.card}>
              <span>Jumlah Kartu Terjual</span>
              <strong>{dataProps?.afterSaleCard?.toLocaleString("id")}</strong>
            </div>
            <div className={Styles.card}>
              <span>Jumlah Kartu Tersedia</span>
              <strong>
                {dataProps?.availableAmountCard?.toLocaleString("id")}
              </strong>
            </div>
            <div className={Styles.card}>
              <span>Jumlah Kartu Dikembalikan</span>
              <strong>{dataProps?.totalReturn?.toLocaleString("id")}</strong>
            </div>
          </div>
        </div>
        <div className={Styles.tableWrapper}>
          <div className={Styles.headerBetween}>
            <h1>Daftar Kartu</h1>
            <div className={Styles.filtering}>
              <SearchbarFMCG
                placeholder={"Cari MSISDN/ICCID"}
                value={searchValue}
                onChange={(e) =>
                  setSearchValue(e.target.value?.replace(/\D/g, ""))
                }
                className={Styles.inputSearch}
                searchWidth="237px"
              />
              <DropdownMui
                value={sortType}
                setValue={setSortType}
                options={["Normal", "Silver", "Bronze"]}
                label="Semua Tipe"
                width={"168px"}
                style={{ borderRadius: "8px", borderColor: "#D9D9D9" }}
              />
              <DropdownMui
                value={sortStatus}
                setValue={setSortStatus}
                options={["Tersedia", "Terjual", "Dikembalikan", "Void"]}
                label="Semua Status"
                width={"168px"}
                style={{ borderRadius: "8px", borderColor: "#D9D9D9" }}
              />
            </div>
          </div>
          <div className={Styles.table}>
            <Table
              data={templateDataPersonil.data}
              columns={templateDataPersonil.columns}
              withNumbering
              className={Styles.tableClass}
              withPagination
              page={+page}
              setPage={setPage}
              limit={+limit}
              setLimit={setLimit}
              totalData={dataListBatch?.totalData || 0}
              totalPage={dataListBatch?.totalPage || 0}
              isAdminIndosat
              isLoading={isLoadingHit}
            />
          </div>
        </div>
      </div>
      {modalCardDetail && (
        <Modal
          isOpen={true}
          onClose={() => {
            setModalCardDetail(null);
          }}
        >
          <DetailStockModal
            onClose={() => {
              setModalCardDetail(null);
            }}
            data={{
              iccid: modalCardDetail.ICCID,
              msisdn: modalCardDetail?.MSISDN,
              price: modalCardDetail?.price,
              status:
                modalCardDetail?.status === "available"
                  ? "Tersedia"
                  : modalCardDetail?.status === "return"
                  ? "Dikembalikan"
                  : modalCardDetail?.status === "sold"
                  ? "Terjual"
                  : "Void",
              type: modalCardDetail?.typeCard,
              _id: modalCardDetail?.id,
            }}
          />
        </Modal>
      )}
    </Modal>
  );
}

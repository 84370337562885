import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

export default function DropdownMui({
  value,
  setValue,
  options,
  width = 100,
  label = "Semua Status",
  style,
}) {
  return (
    <FormControl>
      <Select
        style={style}
        value={value}
        displayEmpty
        onChange={(e) => setValue(e.target.value)}
        SelectDisplayProps={{
          style: {
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: "#ffff",
            borderRadius: 8,
            width: width,
            color: "#757575",
            fontSize: 14,
            fontWeight: 400,
          },
        }}
      >
        <MenuItem value="">{label}</MenuItem>
        {options?.map((option, el) => (
          <MenuItem key={el} onClick={() => setValue(option)} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useEffect, useState } from "react";
import Modal from "..";
import CloseIcon from "@mui/icons-material/Close";
import Styles from "./style.module.scss";
import { Button } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import emptyHandler from "../../../assets/images/empty-handler-batch.png";
import Icon from "../../Icon";
import {
  createPromotorAssignManual,
  returnMSISDN,
} from "../../../async/AdminIndosat/promotor";
import {
  verifyStockMSISDN,
  verifyReturnMSISDN,
} from "../../../async/stock/verifyStock";
import ToastAdminIndosat from "../../Atom/ToastAdminIndosat";
import SuccessImgCheck from "../../../assets/images/checkbox-circle-fill.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
export default function ModalAddNewBatch({
  onClose = () => {},
  isOpen,
  isReturnCard = false,
  setTriggerFetch,
}) {
  const [isSuccessVerify, setIsSuccessVerify] = useState(false);
  const [isNotListedNumber, setIsNotListedNumber] = useState(false);
  const [isAlreadyAssigned, setIsAlreadyAssigned] = useState(false);
  const [isErrorServer, setIsErrorServer] = useState(false);

  const { id } = useParams();

  const [value, setValue] = useState("");
  const [listNumberCreated, setListNumberCreated] = useState([]);

  const handleDeleteListNumber = (id) => {
    setListNumberCreated((prev) => prev.filter((el) => el.id !== id));
  };

  const [loadingHitPost, setLoadingHitPost] = useState(false);
  const handleVerification = async (e) => {
    e.preventDefault();
    setIsSuccessVerify(false);
    setIsNotListedNumber(false);
    setIsAlreadyAssigned(false);
    setLoadingHitPost(true);
    try {
      if (isReturnCard) {
        const res = await verifyReturnMSISDN({ msisdn: value });
        if (res.status === 200) {
          setIsSuccessVerify(true);
          const { data } = res?.data?.response;
          setListNumberCreated((prev) => [
            ...prev,
            { id: data?._id, msisdn: data?.msisdn, batch: data?.batch?.number },
          ]);
          setValue("");
        }
      } else {
        const res = await verifyStockMSISDN({ msisdn: value });
        if (res.status === 200) {
          setIsSuccessVerify(true);
          setListNumberCreated((prev) => [
            ...prev,
            { id: prev.length + 1, msisdn: value },
          ]);
          setValue("");
        }
      }
    } catch (error) {
      const { message } = error?.response?.data;
      if (message === "Number is not found" || message === "Invalid Number") {
        setIsNotListedNumber(true);
      } else if (message === "Number already assigned") {
        setIsAlreadyAssigned(true);
      } else {
        setIsErrorServer(true);
      }
    } finally {
      setLoadingHitPost(false);
    }
  };

  useEffect(() => {
    if (isSuccessVerify) {
      setTimeout(() => {
        setIsSuccessVerify(false);
      }, 3000);
    } else if (isNotListedNumber) {
      setTimeout(() => {
        setIsNotListedNumber(false);
      }, 3000);
    } else if (isAlreadyAssigned) {
      setTimeout(() => {
        setIsAlreadyAssigned(false);
      }, 3000);
    } else if (isErrorServer) {
      setTimeout(() => {
        setIsErrorServer(false);
      }, 3000);
    }
  }, [isAlreadyAssigned, isErrorServer, isNotListedNumber, isSuccessVerify]);

  const [isSuccessCreateBatch, setIsSuccessCreateBatch] = useState(false);
  const [isErrorCreateBatch, setIsErrorCreateBatch] = useState(false);
  const [isLoadingHitCreateBacth, setIsLoadingHitCreateBacth] = useState(false);
  const handleCreateNewBatch = async () => {
    setIsLoadingHitCreateBacth(true);
    try {
      const msisdnToSend = listNumberCreated.map((l) => l?.msisdn);
      const res = await createPromotorAssignManual({
        employeeID: id,
        amount: listNumberCreated?.length,
        msisdn: msisdnToSend,
      });
      if (res.status === 200) {
        setIsSuccessCreateBatch(true);
        setTriggerFetch(Math.random());
      }
    } catch (error) {
      setIsErrorCreateBatch(true);
      console.log("Error create batch: ", error);
    } finally {
      setIsLoadingHitCreateBacth(false);
    }
  };

  const [isSuccessReturnCard, setIsSuccessReturnCard] = useState(false);
  const [isErrorReturnCard, setIsErrorReturnCard] = useState(false);

  const handleReturnCard = async () => {
    setIsErrorReturnCard(false);
    setIsSuccessReturnCard(false);
    setIsLoadingHitCreateBacth(true);

    try {
      const msisdnToSend = listNumberCreated.map((l) => l?.msisdn);
      const res = await returnMSISDN({ msisdn: msisdnToSend });
      if (res.message === "SIM Card Return Success") {
        setIsSuccessReturnCard(true);
        setListNumberCreated([]);
      }
    } catch (error) {
      setIsErrorReturnCard(true);
      console.log("Error return batch: ", error);
    } finally {
      setIsLoadingHitCreateBacth(false);
    }
  };

  useEffect(() => {
    // if (isSuccessCreateBatch) {
    //   setTimeout(() => {
    //     setIsSuccessCreateBatch(false);
    //   }, 3000);
    // }
    if (isErrorCreateBatch) {
      setTimeout(() => {
        setIsErrorCreateBatch(false);
      }, 3000);
    }
  }, [isErrorCreateBatch]);

  useEffect(() => {
    if (isErrorReturnCard) {
      setTimeout(() => {
        setIsErrorReturnCard(false);
      }, 3000);
    }
  }, [isErrorReturnCard]);

  return (
    <>
      <Modal isOpen={isOpen}>
        <Modal
          isOpen={(isSuccessReturnCard && isReturnCard) || isSuccessCreateBatch}
          isTransparent
          bg="transparent"
        >
          <div className={Styles.wrapperModalSuccessReturn}>
            <img src={SuccessImgCheck} alt="success-icon" />
            {isSuccessCreateBatch ? (
              <h4>Berhasil Membuat Batch </h4>
            ) : (
              <h4>Berhasil Terima Kartu</h4>
            )}
            <Button
              sx={{
                borderRadius: "8px",
                paddingBlock: "8px",
                paddingInline: "10px",
                width: "100%",
                textTransform: "capitalize",
              }}
              onClick={() => {
                onClose();
                setIsSuccessReturnCard(false);
                setIsSuccessCreateBatch(false);
              }}
              variant="contained"
            >
              Tutup
            </Button>
          </div>
        </Modal>

        {isErrorCreateBatch && (
          <ToastAdminIndosat
            showNotif
            isRed={true}
            placeholder={"Gagal membuat Batch"}
          />
        )}

        <div
          className={Styles.containerModalAddNewBatch}
          style={{
            display:
              (isReturnCard && isSuccessReturnCard) || isSuccessCreateBatch
                ? "none"
                : "",
          }}
        >
          {isReturnCard && isErrorReturnCard && (
            <ToastAdminIndosat
              showNotif
              isRed={true}
              placeholder={"Gagal menerima Kartu"}
            />
          )}

          <div className={Styles.header}>
            <h3> {isReturnCard ? "Return Kartu" : "Buat Batch Baru"}</h3>
            <CloseIcon className={Styles.closeIcon} onClick={onClose} />
          </div>
          <form onSubmit={handleVerification}>
            <div className={Styles.verifySection}>
              <p className={Styles.desc}>
                Scan atau tulis atau upload nomor MSISDN
              </p>
              <div className={Styles.inputVerify}>
                <label htmlFor="to-verify">Nomor MSISDN</label>
                <div className={Styles.viewRow}>
                  <div className={Styles.wrapperInput}>
                    <QrCodeIcon />
                    <input
                      type="text"
                      onChange={(e) =>
                        setValue(e.target.value.replace(/\D/g, ""))
                      }
                      value={value}
                      id={"to-verify"}
                    />
                  </div>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      paddingBlock: "11px",
                      paddingInline: "10px",
                      width: "100%",
                      textTransform: "capitalize",
                    }}
                    type="submit"
                    variant="contained"
                    disabled={!value}
                  >
                    {loadingHitPost ? "Memverifikasi..." : "Verifikasi"}
                  </Button>
                </div>
              </div>
            </div>
          </form>

          <div className={Styles.mainSection}>
            {(isErrorServer ||
              isNotListedNumber ||
              isAlreadyAssigned ||
              isSuccessVerify) && (
              <div
                className={`${Styles.statusVerification} ${
                  isSuccessVerify
                    ? Styles.success
                    : isErrorServer || isNotListedNumber || isAlreadyAssigned
                    ? Styles.error
                    : ""
                } `}
              >
                <Icon
                  icon={isSuccessVerify ? "circle-check" : "info-circle"}
                  color={isSuccessVerify ? "#22970F" : "#D42701"}
                  size={"24"}
                />
                <span>
                  {isSuccessVerify
                    ? "MSISDN terverifikasi"
                    : isNotListedNumber
                    ? "MSISDN tidak terdaftar"
                    : isAlreadyAssigned
                    ? "MSISDN sudah di assign ke promotor lain"
                    : isErrorServer && "Error something went wrong"}
                </span>
              </div>
            )}
            <div className={Styles.dropdown}>
              <div>
                <span>Nomor MSISDN</span>
                <UnfoldMoreIcon fontSize="10px" />
              </div>
              {isReturnCard && (
                <div>
                  <span>Nomor Batch</span>
                  <UnfoldMoreIcon fontSize="10px" />
                </div>
              )}
            </div>
            <div className={Styles.listNumber}>
              {listNumberCreated.length === 0 ? (
                <div className={Styles.emptyHandler}>
                  <img src={emptyHandler} alt="empty-handler" />
                  <span>Belum ada data yang di masukkan</span>
                </div>
              ) : (
                listNumberCreated.map((el, idx) => (
                  <div
                    className={`${Styles.between} ${
                      idx === listNumberCreated.length - 1 && Styles.borderNone
                    } `}
                    key={`${el?.id}-list-numbers`}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2.25 8.25H21.75M2.25 9H21.75M5.25 14.25H11.25M5.25 16.5H8.25M4.5 19.5H19.5C20.7426 19.5 21.75 18.4926 21.75 17.25V6.75C21.75 5.50736 20.7426 4.5 19.5 4.5H4.5C3.25736 4.5 2.25 5.50736 2.25 6.75V17.25C2.25 18.4926 3.25736 19.5 4.5 19.5Z"
                          stroke="#ADB5BD"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span style={{ width: "170px" }}>{el?.msisdn}</span>
                    </div>
                    {isReturnCard && (
                      <span style={{ width: "120px", fontSize: "14px" }}>
                        {`Batch ${el?.batch}`}
                      </span>
                    )}
                    <CloseIcon
                      onClick={() => handleDeleteListNumber(el.id)}
                      className={Styles.closeIcon}
                    />
                  </div>
                ))
              )}
            </div>
            <div className={Styles.divider}></div>
            <div className={Styles.button}>
              <Button
                sx={{
                  borderRadius: "8px",
                  paddingBlock: "8px",
                  paddingInline: "10px",
                  width: "100%",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                onClick={onClose}
              >
                Batal
              </Button>
              <Button
                sx={{
                  borderRadius: "8px",
                  paddingBlock: "8px",
                  paddingInline: "10px",
                  width: "100%",
                  textTransform: "capitalize",
                }}
                variant="contained"
                disabled={listNumberCreated.length === 0}
                onClick={
                  isReturnCard
                    ? () => handleReturnCard()
                    : () => handleCreateNewBatch()
                }
              >
                {isReturnCard ? "Terima Kartu" : "Buat Batch"}
                {isLoadingHitCreateBacth && "..."}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

import axios from "axios";
import {API} from "../../redux/api";

const fetchAttendanceById = (employeeId, startDate, endDate, token) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/attendance/admin/${employeeId}?startDate=${startDate}&endDate=${endDate}`, {
          headers : {
              Authorization: token
          }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchAttendanceById;

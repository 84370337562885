/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useLocation, useParams } from "react-router";
// import useAuth from "use-auth";

export default function AdminIndosatProtectedRoutes({ Component, ...rest }) {
  const { subpage } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state?.user);
  const { authenticated, showLoading } = useSelector((state) => state.user);
  if (showLoading) {
    return <p>Checking authenticaton..</p>;
  }
  return (
    <Route
      {...rest}
      render={(location, props) => {
        showLoading ? (
          <p> Loading ... </p>
        ) : user?.bio?.clientCode?.[0] === "IST" ? (
          // children,
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/admin-indosat", state: props.location }}
          />
        );
      }}
    />
  );
}

import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React from "react";

const SearchbarFMCG = ({
  value,
  onChange,
  searchWidth,
  placeholder,
  className,
}) => {
  return (
    <>
      <FormControl>
        <OutlinedInput
          id="outlined-adornment-weight"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton>
                <i className="bx bx-search" />
              </IconButton>
            </InputAdornment>
          }
          style={{
            borderRadius: 8,
            padding: 0,
            height: 40,
            width: searchWidth,
            backgroundColor: "#FFFFFF",
          }}
          className={className}
        />
      </FormControl>
    </>
  );
};

export default SearchbarFMCG;

import axios from "axios";
import { API } from "../../api";
import * as types from "../../typeAdminIndosat";

export const fetchAllStock = (token, page, limit, search, status, type) => {
  return (dispatch) => {
    dispatch({ type: types?.STOCK_BEGIN });
    axios
      .get(
        `${API}/stock/?page=${page}&limit=${limit}&search=${search}&status=${status}&type=${type}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_STOCK,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.STOCK_ERROR,
          payload: err.response,
        });
        console.log("ERROR", err);
      });
  };
};

export const fetchDetailStock = (token, id) => {
  return (dispatch) => {
    dispatch({ type: types.STOCK_BEGIN });
    axios
      .get(`${API}/stock/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_DETAIL_STOCK,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.STOCK_ERROR,
          payload: err.response.data,
        });
        console.log("ERROR", err);
      });
  };
};

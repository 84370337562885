import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "../../../../components/SuperAdmin/_user/TableUsers/UserTable/UserTable";
import UnderMaintenance from "../../../../components/_underMaintenancePage";
import { fetchAllUsers } from "../../../../redux/actions/SuperAdmin/userAdmin";
// import {} from '../../../../components/SuperAdmin/_user/TableUsers'
export default function UserSA() {
  // const [selectedUserType, setSelectedUserType] = useState("All");
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const isMaintenance = false;
  // eslint-disable-next-line no-unused-vars
  const { users, loadingAllUsers, errorMessage } = useSelector((state) => state?.userAdmin);

  useEffect(() => {
    dispatch(fetchAllUsers(token));
  }, [dispatch, token]);

  // console.log(errorMessage, 'error woy')

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | User Settings</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          User Settings
        </h2>
      </div>

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* <TableUserMenu /> */}
          {isMaintenance ? (
            <UnderMaintenance />
          ) : (
            <UserTable users={users} loading={loadingAllUsers} />
          )}
          {/* {isMaintenance ?  <UnderMaintenance /> : <TableUserMenu selectedUserType={selectedUserType} setSelectedUserType={setSelectedUserType}/>} */}
        </Grid>
      </Grid>
    </Container>
  );
}

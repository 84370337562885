import { useState } from "react";
import Icon from "../../Icon";
import Styles from "./styles.module.scss";
import { useDropzone } from "react-dropzone";

export default function UploadCard({
  files = null,
  setFiles = () => {},
  title = "Taruh file di kotak bawah ini",
  imageOnly = false,
}) {
  //   const inputFileRef = useRef();
  const [error, setError] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: `${
      imageOnly ? "image/*" : "application/vnd.ms-excel, .xlsx, .xlx, .numbers"
    }`, // Tambahkan ekstensi .numbers
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log(file.type);
      if (acceptedFiles?.length > 0) {
        if (
          (!imageOnly &&
            (file.type === "application/vnd.ms-excel" ||
              file.name.endsWith(".xlsx") ||
              file.name.endsWith(".numbers"))) ||
          (imageOnly && file.type.includes("image"))
        ) {
          setFiles(file);
          setError("");
        } else {
          setError(
            imageOnly
              ? "File yang dapat di upload hanya file tipe image"
              : "Format file yang dapat di upload hanya format .xlsx atau .numbers."
          );
        }
      } else {
        setError(
          imageOnly
            ? "File yang dapat di upload hanya file tipe image"
            : "Format file yang dapat di upload hanya format .xlsx atau .numbers."
        );
      }
    },
  });

  //   console.log(files);
  return (
    <div className={Styles.body}>
      <span>{title || "-"}</span>

      {files && !imageOnly && (
        <div className={Styles.filesBox}>
          <div className={Styles.left}>
            <div>
              <Icon icon={"excel"} size={20} />
            </div>
            <span>{files?.name || "-"}</span>
          </div>

          <div
            className={Styles.right}
            onClick={() => {
              setFiles(null);
            }}
          >
            <Icon icon={"x-circle"} size={20} color={"#CED4DA"} />
          </div>
        </div>
      )}
      {files && imageOnly && (
        <div className={Styles.imageContainer} {...getRootProps()}>
          <input
            {...getInputProps()}
            type="file"
            // ref={inputFileRef}
            name="file"
            hidden
            // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <img
            src={files ? URL.createObjectURL(files) : ""}
            alt=""
            className={Styles.imagePreview}
          />
        </div>
      )}
      {!files && (
        <div
          className={`${Styles.container} ${error && Styles.error}`}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            type="file"
            // ref={inputFileRef}
            name="file"
            hidden
            // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />

          <div>
            <Icon icon={"upload-2"} color={"#C2C2C2"} size={40} />
          </div>
          <span>Drag file ke sini untuk upload</span>
          <span>atau pilih file dengan</span>
          <span>Klik di sini</span>
        </div>
      )}

      {error && <span>*{error}</span>}
    </div>
  );
}

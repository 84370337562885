/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Grid, Container, Button } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
// import { EditEmployeeExternal } from "../../../components/External/Employee";
import { useDispatch, useSelector } from "react-redux";
// import { fetchEmployeeExternalById } from "../../../redux/actions/External/employeesExternalActions";
// import {
//   UseGetAllLevel,
//   UseGetAllRegion,
//   UseGetListPositionExternal,
// } from "../../../components/utils/hooks";
// import UseListLocation from "../../../components/utils/hooks/useListLocation";
import { fetchAllEmployeeById } from "../../../redux/actions/SuperAdmin/employeesActions";
import EditEmployeeAdminIndosat from "../../../components/IndosatAdmin/EditEmployee";

function EditEmployeeAdminIndosatPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isFromPromotorPage = location.state?.isFromPromotorPage;
  const isSpv = localStorage.getItem("isSPV");
  const token = localStorage.getItem("accessToken");
  const clientCode = localStorage.getItem("clientCode");
  const employeeInfo = location?.state?.data;
  const { employeeById } = useSelector((state) => state.employees);
  // const employeeInfo = location?.state?.data;
  // const getLevel= ['STAFF', "LEADER"]

  const dataEmployee = useMemo(() => {
    // return location?.state?.user
    return employeeById[0]?.user;
  }, [employeeById]);

  useEffect(() => {
    dispatch(
      fetchAllEmployeeById(
        token,
        employeeInfo?.employeeId,
        `clientCode=${
          location.state?.isAdminIndosat ? "IST" : employeeInfo?.clientCode
        } `
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(location.state?.data, 'cek location data state');
  // useEffect(() => {
  //   dispatch(fetchAllEmployeeById(token))
  // },[])

  if (!employeeInfo?.employeeId) {
    return null;
  }

  // useEffect(() => {
  //   dispatch(fetchEmployeeExternalById(token, id));
  // }, []);

  // console.log(employeeByIdExternal, "check");

  // if (!employeeByIdExternal) return null;
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Edit Employee</title>
      </Helmet>
      <div className="top__items-settings">
        <Breadcrumb>
          <Button
            onClick={() =>
              history.push(
                isFromPromotorPage
                  ? "/admin-indosat/promotor-list/" + employeeInfo?.employeeId
                  : "/admin-indosat/employee"
              )
            }
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              {isFromPromotorPage ? "Detail Promotor" : "Employee"}
            </span>
          </Button>
          <Button
            onClick={() => history.go(-1)}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              {dataEmployee?.name}
            </span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Employee</span>
        </Breadcrumb>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <EditEmployeeAdminIndosat
            isFromPromotorPage={isFromPromotorPage}
            data={dataEmployee}
            token={token}
            employeeID={employeeInfo?.employeeId}
          />
          {/* <EditEmployeeExternal
            id={id}
            employeeData={employeeByIdExternal}
            level={level}
            regional={getRegional}
            position={getPosition}
            listLocation={getListLocation}
          /> */}
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditEmployeeAdminIndosatPage;

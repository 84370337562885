import React, { useState } from "react";
import { Redirect } from "react-router";
import MiniScreen from "../../utils/MiniScreen";
// import RoutesAdminMWS from "../../AdminMWS/Routes/RoutesAdminMWS";
import TopnavAdminMWS from "../../AdminMWS/TopnavAdminMWS/TopnavAdminMWS";
// import SidebarAdminMWS from "../../AdminMWS/SidebarAdminMWS/SidebarAdminMWS";
import RoutesAdminIndosat from "../../IndosatAdmin/Routes";
import SidebarAdminIndosat from "../../IndosatAdmin/SidebarAdminIndosat";
import SnackbarComponent from "../../utils/Snackbar/SnackbarComponent";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const LayoutAdminIndosat = () => {
  const token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);

  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      render={(props) => (
        <>
          <div
            className="layout"
            // style={{ height: '100vh'}}
          >
            <SidebarAdminIndosat
              {...props}
              onOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <div className="layout__content">
              <TopnavAdminMWS onOpenSidebar={() => setOpen(true)} />
              <div className="layout__content-main">
                <SnackbarComponent />
                <RoutesAdminIndosat />
              </div>
            </div>
          </div>
          <div className="mini__screen-layout">
            <MiniScreen />
          </div>
        </>
      )}
    />
  );
};

export default LayoutAdminIndosat;

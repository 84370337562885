import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DELETE_ICON from "../../../assets/images/delete-icon-schema-v2.png";
import { deleteAccountNumber } from "../../../async/AdminIndosat/accountNumber";
import { fetchAccountNumbers } from "../../../redux/actions/AdminIndosat/accountNumberActions";
import Styles from "./ModalLoanSA.module.css";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

export default function DeleteConfirmationModal({
  dataToDelete,
  description,
  label,
  setSuccess,
  onClose,
}) {
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState(null)

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);
  const dispatch = useDispatch();

  // console.log(active._id)

  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await deleteAccountNumber(dataToDelete?._id)
      setSuccess('Nomor rekening berhasil dihapus')
      dispatch(
        fetchAccountNumbers()
      );
      onClose()
    } catch (err) {
      setError(err)
      console.log(err, 'Error deleting scheme')
    } finally {
      setIsDeleting(false)
    }
  };
  return (
    <div className={Styles.ModalDeleteBackground}>
      <div className={Styles.ModalContainerContent}>
        <div className={Styles.WrapperItemModalDelete}>
          <div className={Styles.ItemTopModalDelete}>
            <img src={DELETE_ICON} alt="delete-icon" />
          </div>
        </div>
        <div className={Styles.ContentModalDelete} style={{ paddingTop: 24 }}>
          <div className={Styles.TextModalDelete}>
            <span>{label}</span>
            <span>{description}</span>
          </div>
          <div className={Styles.error}>
            {
              error &&
              <span>
                *Something went wrong
              </span>
            }
          </div>
          <div className={Styles.WrapperButtonModalDelete}>
            <Button
              style={{
                border: "1px solid #333333",
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                textTransform: "none",
                color: "#333333",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={isDeleting ? () => { } : onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#1571DE",
                textTransform: "none",
                color: "#FFFFFF",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              disabled={isDeleting}
              onClick={isDeleting ? () => { } : handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
// import { API } from "../../redux/api";
import { API } from "../../redux/api";

export const createStock = (token, body) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/stock/upload`, body, {
        headers: {
          Authorization: token,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const updateStatusStock = (token, id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.patch(
        `${API}/stock/void/${id}`,
        {}, // Data payload, kosong jika tidak diperlukan
        {
          headers: {
            Authorization: token, // Tambahkan "Bearer" jika API membutuhkan
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const _acceptCashPayment = (id) =>
  new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("accessToken");

    try {
      const { data } = await axios.patch(
        `${API}/transaction/acceptCash/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
export const _acceptTransferPayment = (id, body) =>
  new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    if(body){
      formData.append("image", body);
    }
    try {
      const { data } = await axios.patch(
        `${API}/transaction/acceptTransfer/${id}`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(id, body, data);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const _selectPayment = (id, paymentMethod) =>
  new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("accessToken");

    try {
      const { data } = await axios.patch(
        `${API}/transaction/changeMethod/${id}`,
        { paymentMethod },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
export const _resetPayment = (id) =>
  new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("accessToken");

    try {
      const { data } = await axios.patch(
        `${API}/transaction/resetMethod/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

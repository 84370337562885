import * as types from "../../typeAdminIndosat";

const initialstate = {
  allDataStock: {},
  loadingDataStock: false,
  errorStock: {},
  detailDataStock: {},
};

const StockAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.STOCK_BEGIN: {
      return {
        ...state,
        loadingDataStock: true,
      };
    }
    case types.STOCK_ERROR: {
      return {
        ...state,
        loadingDataStock: false,
        errorStock: action.payload,
      };
    }

    case types.FETCH_ALL_STOCK: {
      return {
        ...state,
        loadingDataStock: false,
        allDataStock: action?.payload,
      };
    }

    case types.FETCH_DETAIL_STOCK: {
      return {
        ...state,
        loadingDataStock: false,
        detailDataStock: action.payload,
      };
    }

    default:
      return state;
  }
};

export default StockAdminIndosatReducer;

import * as types from "../../typeAdminIndosat";

const initialstate = {
  allDataPromotors: {},
  loadingDataPromotors: false,
  errorPromotors: {},
};

const PromotorsAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_PROMOTORS_BEGIN: {
      return {
        ...state,
        loadingDataPromotors: true,
      };
    }
    case types.FETCH_ALL_PROMOTORS_ERROR: {
      return {
        ...state,
        loadingDataPromotors: false,
        errorPromotors: action.payload,
      };
    }

    case types.FETCH_ALL_PROMOTORS: {
      return {
        ...state,
        loadingDataPromotors: false,
        allDataPromotors: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default PromotorsAdminIndosatReducer;

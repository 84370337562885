import axios from "axios";
import { API } from "../../redux/api";

export function createPromotorAssignManual(payload = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${API}/promotor/assign`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export const assignCardsByExcel = (body) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/promotor/excel-assign`, body, {
        headers: {
          Authorization: localStorage?.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const getDataDetailPromotorAdminIndosat = (id, page = 1, limit = 10) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(
        `${API}/promotor/${id}?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: localStorage?.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const getDataModalDetailBatchPromotorAdminIndosat = (
  id,
  page = 1,
  limit = 10,
  search = "",
  type = "",
  status = ""
) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(
        `${API}/promotor/batch/${id}?page=${page}&limit=${limit}&code=${search}&type=${type}&status=${status}`,
        {
          headers: {
            Authorization: localStorage?.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const returnMSISDN = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/promotor/return`, payload, {
        headers: {
          Authorization: localStorage?.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

// export const getDetailAmountBatch = (id) =>
//   new Promise(async (resolve, reject) => {
//     try {
//       const data = await axios.get(`${API}/promotor/top/detail?id=${id}`, {
//         headers: {
//           Authorization: localStorage?.getItem("accessToken"),
//         },
//       });
//       resolve(data);
//     } catch (error) {
//       reject(error);
//     }
//   });

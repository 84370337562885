import { Autocomplete, styled, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import Button from '../../Button'
import Icon from '../../Icon'
import Modal from '../../Modal'
import Styles from './style.module.scss'
import { createAccountNumber, updateAccountNumber } from '../../../async/AdminIndosat/accountNumber'
import { useDispatch } from 'react-redux'
import { fetchAccountNumbers } from '../../../redux/actions/AdminIndosat/accountNumberActions'

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      fontFamily: 'Poppins',
      borderRadius: 8,
      border: '1px solid #d9d9d9',
      color: '#0a0a0a',
    },
  },
});

export default function AddAccountNumberModal({
  isOpen,
  onClose,
  // onSubmit,
  allDataBanks,
  setSuccess,
  isEdit = false,
  defaultData,
  loadingDataBanks,
  errorBanks,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedBank, setSelectedBank] = useState(null)
  const [accountNumber, setAccountNumber] = useState(defaultData?.accountNumber || '')
  const [accountName, setAccountName] = useState(defaultData?.accountName || '')

  const [error, setError] = useState(null)

  useEffect(() => {
    if (isEdit && allDataBanks?.length) {
      setAccountNumber(defaultData?.accountNumber)
      setAccountName(defaultData?.accountName)
      setSelectedBank(allDataBanks?.find(b => b?.code === defaultData?.bankCode) || null)
      // setSelectedBank({
      //   name: defaultData?.bankName,
      //   code: defaultData?.bankCode,
      //   swiftCode: defaultData?.swiftCode,
      // })
    }
  }, [allDataBanks, defaultData?.accountName, defaultData?.accountNumber, defaultData?.bankCode, isEdit])

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const form = new URLSearchParams()
      form?.append('bankName', selectedBank?.name)
      form?.append('bankCode', selectedBank?.code)
      form?.append('swiftCode', selectedBank?.swiftCode)
      form?.append('accountNumber', accountNumber)
      form?.append('accountName', accountName)

      isEdit ? await updateAccountNumber(defaultData?._id, form) : await createAccountNumber(form)
      dispatch(
        fetchAccountNumbers()
      );
      isEdit ? setSuccess('Nomor rekening berhasil diperbaharui') : setSuccess('Nomor rekening berhasil ditambahkan')
      onClose()
    } catch (err) {
      setError(err?.response?.data?.error || 'Something went wrong')
      console.log(error, 'Error creating account number')
    } finally {
      setIsSubmitting(false)
    }
  }

  console.log(selectedBank, 'sb')

  return (
    <Modal isOpen={isOpen} onClose={isSubmitting ? () => { } : onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={'control-point-solid'} size={23} />
          <h3>Tambah Nomor Rekening</h3>
        </div>
        <div className={Styles.content}>
          <div>
            <InputLabel required>
              Nama Bank
            </InputLabel>
            <Autocomplete
              disablePortal
              id="attendee"
              value={selectedBank}
              getOptionLabel={(option) => option.name}
              options={allDataBanks || []}
              onChange={(event, newValue) => setSelectedBank(newValue)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option._id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => <MuiTextField
                {...params}
                size="small"
                sx={{
                  width: '100%',
                  background: 'var(--main-bg)'
                }}
                placeholder='Belum dipilih'
              />
              }
            />
          </div>
          <div>
            <InputLabel required>
              Nomor Rekening
            </InputLabel>
            <MuiTextField
              size='sm'
              style={{ height: 40, width: '100%' }}
              placeholder='Masukkan nomor rekening'
              value={accountNumber}
              type='number'
              onChange={(e) => setAccountNumber(e.target.value)}
              inputProps={{
                style: {
                  height: 40,
                  width: '100%',
                  padding: '0 14px',
                  fontFamily: 'Poppins',
                  fontSize: 14,
                },
              }}

            />
          </div>
          <div>
            <InputLabel required>
              Nama Pemegang Rekening
            </InputLabel>
            <MuiTextField
              size='sm'
              style={{ height: 40, width: '100%' }}
              placeholder='Masukkan nama pemegang rekening'
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              inputProps={{
                style: {
                  height: 40,
                  width: '100%',
                  padding: '0 14px',
                  fontFamily: 'Poppins',
                  fontSize: 14,
                },
              }}

            />
          </div>

        </div>
        <div className={Styles.error}>
          {
            error &&
            <span>
              *{error}
            </span>
          }
        </div>
        <div className={Styles.actions}>
          <Button
            placeHolder='Cancel'
            type='primary-outlined'
            disabled={isSubmitting}
          />
          <Button
            placeHolder='Save'
            disabled={isSubmitting || !selectedBank || !accountNumber || !accountName}
            isLoading={isSubmitting}
            onClick={!isSubmitting ? handleSubmit : () => { }}
          />
        </div>
      </div>
    </Modal>
  )
}

const InputLabel = ({
  children,
  required
}) => {

  return (
    <div className={Styles.label}>
      <span>{children}</span>
      {required && <span className={Styles.required}>*</span>}
    </div>
  )
}
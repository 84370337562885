import axios from "axios";
import { API } from "../../redux/api";

export const createAccountNumber = (body) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/account-number`, body, {
        headers: {
          Authorization: localStorage?.getItem('accessToken'),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
export const updateAccountNumber = (id,body) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.patch(`${API}/account-number/${id}`, body, {
        headers: {
          Authorization: localStorage?.getItem('accessToken'),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
export const deleteAccountNumber = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.delete(`${API}/account-number/${id}`, {
        headers: {
          Authorization: localStorage?.getItem('accessToken'),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
import { Avatar } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { titleCase } from '../../../helpers/titleCase';
import { useDebounce } from '../../../hooks/useDebounce';
import { fetchPromotors } from '../../../redux/actions/AdminIndosat/promotorActions';
import Button from '../../Button';
import DropdownMui from '../../DropdownMUI';
import Modal from '../../Modal';
import SearchbarSA from '../../utils/SearchbarSA/SearchbarSA';
import AddStockModal from '../ModalAdminIndosat/AddStockModal';
import Table from '../Table';
import Styles from './style.module.css';
import { assignCardsByExcel } from '../../../async/AdminIndosat/promotor';
import ToastAdminIndosat from '../../Atom/ToastAdminIndosat';



export default function PromotorListLayout() {
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const history = useHistory();

  const [showAssign, setShowAssign] = useState(false);
  const [files, setFiles] = useState(null);

  const [isAssigning, setIsAssigning] = useState(false);
  const [successAssign, setSuccessAssign] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch()

  // eslint-disable-next-line no-unused-vars
  const { allDataPromotors, errorPromotors, loadingDataPromotors } = useSelector(
    (state) => state.promotorsAdminIndosat
  )

  useEffect(() => {
    dispatch(
      fetchPromotors(
        page,
        limit,
        debouncedSearch,
        sort ? (
          sort?.toLowerCase()?.includes('terendah') ? 'asc' : 'desc'
        ) : ''
      )
    );
  }, [debouncedSearch, dispatch, limit, page, sort]);


  const handleAssignCards = async () => {
    try {
      setIsAssigning(true);
      const formData = new FormData();
      formData.append("file", files);
      await assignCardsByExcel(formData);
      setSuccessAssign(true);
      setError("");
      setFiles(null);
      dispatch(
        fetchPromotors(
          page,
          limit,
          debouncedSearch,
          sort ? (
            sort?.toLowerCase()?.includes('terendah') ? 'asc' : 'desc'
          ) : ''
        )
      );
    } catch (error) {
      setError(error?.response?.data?.error);
      console.log(error);
    } finally {
      setIsAssigning(false)
    }
  };




  const TABLE_HEAD = [
    {
      name: "name", title: "Nama Promotor", align: 'left', width: '35%',
      renderData: (row) => (
        <div className={Styles.user}>
          <Avatar src={row?.profilePicture} sx={{ color: "white", backgroundColor: "black", cursor: 'pointer', width: 24, height: 24 }} />
          <span>{row?.name}</span>
        </div>
      ),
    },
    { name: "employeeID", title: "NRK", align: "left", width: "15%" },
    { name: "phoneNumber", title: "No Telepon", align: "left", width: "15%" },
    {
      name: "cardAmount",
      title: "Jumlah Kartu On Hand",
      align: "right",
      width: "40%",
    },
  ];

  const data = useMemo(() => {
    return allDataPromotors?.data?.map(obj => {
      return {
        ...obj,
        name: titleCase(obj?.name),
        employeeID: obj?.employeeId,
        phoneNumber: obj?.phoneNumber,
        cardAmount: `${obj?.stockOnHand}`,
        profilePicture: obj?.profilePicture,
      }
    })

  }, [allDataPromotors?.data])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <SearchbarSA
            placeholder={'Cari nama promotor'}
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
          <DropdownMui
            value={sort}
            setValue={setSort}
            options={[
              'jml On Hand Tebanyak',
              'jml On Hand Terendah'
            ]}
            label='Urutkan Berdasarkan'
            width={'168px'}
          />
          {
            (search || sort) &&
            (
              <Button
                type='danger'
                placeHolder='Reset Filter'
                onClick={() => {
                  setSearch('')
                  setSort('')
                }}
              />
            )
          }

        </div>
        <Button
          type='primary'
          placeHolder='Assign Kartu'
          icon='Upload'
          onClick={() => setShowAssign(true)}
        />

      </div>
      <Table
        columns={TABLE_HEAD}
        data={data}
        withNumbering
        withPagination
        page={+page}
        setPage={setPage}
        limit={+limit}
        setLimit={setLimit}
        totalData={+allDataPromotors?.totalData || 0}
        totalPage={+allDataPromotors?.totalPages || 1}
        isAdminIndosat
        isLoading={loadingDataPromotors}
        onClickRow={(row) => history.push(`/admin-indosat/promotor-list/${row?.employeeID}`)}
      />
      <ToastAdminIndosat
        showNotif={errorPromotors?.isValidate}
        placeholder={errorPromotors?.message}
        isRed
      />
      <Modal onClose={() => { }} isOpen={showAssign || successAssign}>
        <AddStockModal
          handleDone={() => {
            setShowAssign(false);
            setSuccessAssign(false);
          }}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleAssignCards}
          isLoading={isAssigning}
          success={successAssign}
          error={error}
          isAssign
        />
      </Modal>
    </div>
  );
}

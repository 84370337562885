import axios from "axios";
import { API } from "../../api";
import * as types from "../../typeAdminIndosat";

export const fetchAccountNumbers = () => {
  const token = localStorage.getItem("accessToken");

  return (dispatch) => {
    dispatch({ type: types?.FETCH_ALL_ACCOUNT_NUMBERS_BEGIN });
    axios
      .get(
        `${API}/account-number`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_ACCOUNT_NUMBERS,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        console.log(err, 'ini error')
        dispatch({
          type: types.FETCH_ALL_ACCOUNT_NUMBERS_ERROR,
          payload: err?.response?.data,
        });
        console.log("ERROR", err);
      });
  };
};


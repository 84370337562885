export const STOCK_BEGIN = "STOCK_BEGIN";
export const STOCK_ERROR = "STOCK_ERROR";
export const FETCH_ALL_STOCK = "FETCH_ALL_STOCK";
export const FETCH_DETAIL_STOCK = "FETCH_DETAIL_STOCK";

export const FETCH_ALL_PROMOTORS_BEGIN = "FETCH_ALL_PROMOTORS_BEGIN";
export const FETCH_ALL_PROMOTORS_ERROR = "FETCH_ALL_PROMOTORS_ERROR";
export const FETCH_ALL_PROMOTORS = "FETCH_ALL_PROMOTORS";

export const FETCH_ALL_BANKS_BEGIN = "FETCH_ALL_BANKS_BEGIN";
export const FETCH_ALL_BANKS_ERROR = "FETCH_ALL_BANKS_ERROR";
export const FETCH_ALL_BANKS = "FETCH_ALL_BANKS";

export const FETCH_ALL_ACCOUNT_NUMBERS_BEGIN =
  "FETCH_ALL_ACCOUNT_NUMBERS_BEGIN";
export const FETCH_ALL_ACCOUNT_NUMBERS_ERROR =
  "FETCH_ALL_ACCOUNT_NUMBERS_ERROR";
export const FETCH_ALL_ACCOUNT_NUMBERS = "FETCH_ALL_ACCOUNT_NUMBERS";

export const SALES_BEGIN = "SALES_BEGIN";
export const SALES_ERROR = "SALES_ERROR";
export const FETCH_ALL_SALES = "FETCH_ALL_SALES";

export const DASHBOARD_BEGIN = "DASHBOARD_BEGIN";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const FETCH_ALL_DASHBOARD = "FETCH_ALL_DASHBOARD";

import axios from "axios";
import { API } from "../../redux/api";

export const verifyReturnMSISDN = (body) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await axios.post(`${API}/promotor/verify`, body, {
        headers: {
          Authorization: localStorage?.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const verifyStockMSISDN = (body) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await axios.post(`${API}/stock/verify`, body, {
        headers: {
          Authorization: localStorage?.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

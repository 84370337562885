import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../Icon";
import Styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { fetchDetailStock } from "../../../../redux/actions/AdminIndosat/stockActions";
import moment from "moment";
import { Tooltip } from "@mui/material";

export default function DetailStockModal({ onClose, data }) {
  const token = localStorage.getItem("accessToken");
  // console.log(data, "test");
  const dispatch = useDispatch();
  const [dataLogs, setDataLogs] = useState([]);

  const { detailDataStock, errorStock, loadingDataStock } = useSelector(
    (state) => state.stockAdminIndosat
  );

  useEffect(() => {
    if (data) {
      dispatch(fetchDetailStock(token, data?._id));
    }
  }, [data, dispatch, token]);

  useEffect(() => {
    if (detailDataStock && !loadingDataStock) {
      const combinedData = [
        ...(detailDataStock?.assign ?? [])?.map((item) => ({
          date: item.assignAt,
          name: item.assignTo || "-",
          type: "assign",
        })),
        ...(detailDataStock?.returned ?? [])?.map((item) => ({
          date: item.returnedAt,
          name: "Dikembalikan ke gudang",
          type: "returned",
        })),
        ...(detailDataStock?.sold ?? [])?.map((item) => ({
          date: item?.soldAt,
          name: item?.soldTo,
          type: "soldTo",
        })),
        {
          date: detailDataStock?.uploadAt,
          name: "Diunggah kedalam sistem",
          type: "uploadAt",
        },
      ];

      // Sort the data
      combinedData.sort((a, b) => {
        const dateA = a.date ? new Date(a.date) : null;
        const dateB = b.date ? new Date(b.date) : null;

        if (!dateA) return 1; // Items with null date go to the bottom
        if (!dateB) return -1; // Items with null date go to the bottom

        return dateB - dateA; // Newest to oldest, including time
      });

      setDataLogs(combinedData);
    }
  }, [detailDataStock, loadingDataStock]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <div>
            <Icon icon={"sim-card"} size={24} color={"#0A0A0A"} />
          </div>
          <span>Detail Kartu</span>
        </div>

        <div
          className={`${Styles.right} ${
            data?.status === "Void" && Styles.void
          } ${data?.status === "Terjual" && Styles.sold}
           ${data?.status === "Dikembalikan" && Styles.return}
           `}
        >
          {data?.status}
        </div>
      </div>

      <div className={Styles.detail}>
        <div>
          <span>No MSISDN</span>
          <span>{data?.msisdn || "-"}</span>
        </div>
        <div>
          <span>No ICCID</span>
          <span>{data?.iccid || "-"}</span>
        </div>
      </div>

      <div className={Styles.log}>
        {loadingDataStock ? (
          <div className={Styles.loadingBox}>
            <span>Loading...</span>
            <span>Mohon tunggu sebentar, data sedang dimuat</span>
          </div>
        ) : dataLogs?.length > 0 ? (
          dataLogs?.map((el, idx) => {
            return (
              <div key={idx} className={Styles.listLog}>
                <Tooltip
                  title={
                    <div>
                      {el?.date
                        ? moment(el?.date)
                            .utcOffset("+07:00")
                            .format("DD MMM YYYY, HH:mm [WIB]")
                        : "-"}
                    </div>
                  }
                >
                  <div className={Styles.left}>
                    {el?.date
                      ? moment(el?.date)
                          .utcOffset("+07:00")
                          .format("DD MMM YYYY, HH:mm [WIB]")
                      : "-"}
                  </div>
                </Tooltip>
                <div className={Styles.middle}>
                  <div
                    className={`${Styles.bullet} ${
                      idx !== 0 && Styles.disabled
                    }`}
                  >
                    <div></div>
                  </div>

                  {idx === dataLogs?.length - 1 ? null : (
                    <div className={Styles.line}></div>
                  )}
                </div>

                <Tooltip
                  title={
                    <div>
                      {el?.name
                        ? el?.type === "returned" || el.type === "uploadAt"
                          ? el?.name
                          : el?.type === "assign"
                          ? "Diberikan kepada promotor"
                          : el?.type === "soldTo"
                          ? "Terjual ke toko"
                          : el?.name
                        : "-"}{" "}
                      {el?.type === "assign" || el?.type === "soldTo" ? (
                        <span
                          className={`${el?.type === "soldTo" && Styles.blue}`}
                        >
                          {el?.name || "-"}
                        </span>
                      ) : null}
                    </div>
                  }
                >
                  <div className={Styles.right}>
                    {el?.type
                      ? el?.type === "returned" || el.type === "uploadAt"
                        ? el?.name || "-"
                        : el?.type === "assign"
                        ? "Diberikan kepada promotor"
                        : el?.type === "soldTo"
                        ? "Terjual ke toko"
                        : el?.name || "-"
                      : "-"}{" "}
                    {el?.type === "assign" || el?.type === "soldTo" ? (
                      <span
                        className={`${el?.type === "soldTo" && Styles.blue}`}
                      >
                        {el?.name || "-"}
                      </span>
                    ) : null}
                  </div>
                </Tooltip>
              </div>
            );
          })
        ) : (
          <div className={Styles.dataNotFound}>Data Log Belum Tersedia</div>
        )}
      </div>
    </div>
  );
}

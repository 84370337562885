import { Button, Link, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "../../Icon";
import TabHeader from "../../Atom/TabHeader";
import Table from "../Table";
import ModalDetailBatchAdminIndosat from "./ModalDetailBatch";
import UseOutsideClick from "../../../hooks/useOutsideClick";
import ModalAddNewBatch from "../../Modal/ModalAddNewBatch";
import Modal from "../../Modal";
import AddStockModal from "../ModalAdminIndosat/AddStockModal";
import {
  assignCardsByExcel,
  getDataDetailPromotorAdminIndosat,
} from "../../../async/AdminIndosat/promotor";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { capitalize } from "lodash";
import AVA_DEFAULT from "../../../assets/images/ava-default.png";
import moment from "moment";
import { Link as LinkTo } from "react-router-dom";
import Skeleton, { CircleSkeleton } from "../../Atom/Skeleton";

export default function DetailPromotorAdminIndosat() {
  const { id } = useParams();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#1571DE"
      href="/admin-indosat/promotor-list"
    >
      Daftar Promotor
    </Link>,
    <Typography key="2" color={"#1571DE"}>
      Detail Promotor
    </Typography>,
  ];
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [dataPagination, setDataPagination] = useState();
  const [openModalDetailBatch, setOpenModalDetailBatch] = useState(null);
  const [isLoadingHit, setIsLoadingHit] = useState(true);

  const [triggerFetch, setTriggerFetch] = useState(false);

  const [dataDetailPromotor, setDataDetailPromotor] = useState();
  const getDataDetailPromotor = useCallback(async () => {
    setIsLoadingHit(true);
    try {
      const res = await getDataDetailPromotorAdminIndosat(id, page, limit);
      if (res.status === 200) {
        setDataDetailPromotor(res?.data?.response?.data);
        setDataPagination(res?.data?.response);
      }
    } catch (error) {
      console.log("Error get detail promotor", error);
    } finally {
      setIsLoadingHit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, id, triggerFetch]);

  useEffect(() => {
    getDataDetailPromotor();
  }, [getDataDetailPromotor]);

  const templateDataPersonil = useMemo(() => {
    return {
      data:
        dataDetailPromotor?.batchAssign?.map((b) => ({
          id: b?._id,
          batch: "Batch " + b?.number,
          dateAssign: b?.assignAt,
          cardInAssign: b?.amount || 0,
          afterSaleCard: b?.stockSold || 0,
          availableAmountCard: b?.stockAvailable || 0,
          totalReturn: b?.stockReturn,
        })) || [],
      columns: [
        {
          name: "batch",
          title: <TabHeader text="No Batch" />,
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModalDetailBatch(row?.id)}
            >
              {row?.batch}
            </span>
          ),
        },
        {
          name: "dateAssign",
          title: <TabHeader text="Tanggal Assign" />,
          renderData: (row) => (
            <span>{moment(row?.dateAssign).format("ll")}</span>
          ),
        },

        {
          name: "cardInAssign",
          width: "201px",
          title: <TabHeader text="Jumlah Kartu Di Assign" />,
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ marginRight: "0.5rem" }}>{row?.cardInAssign}</span>
            </div>
          ),
        },

        {
          name: "afterSaleCard",
          width: "201px",
          title: <TabHeader text="Jumlah Kartu Terjual" />,
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ marginRight: "1.5rem" }}>
                {row?.afterSaleCard}
              </span>
            </div>
          ),
        },

        {
          name: "availableAmountCard",
          width: "201px",
          title: <TabHeader text="Jumlah Kartu Tersedia" />,
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ marginRight: "1rem" }}>
                {row?.availableAmountCard}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataDetailPromotor?.batchAssign]);
  const [isOpenUploadCard, setIsOpenUploadCard] = useState(false);

  const dropdownAssignRef = useRef(null);

  const boxDropdown = UseOutsideClick(dropdownAssignRef);

  useEffect(() => {
    if (boxDropdown && isOpenUploadCard) {
      setIsOpenUploadCard(false);
    }
  }, [boxDropdown, isOpenUploadCard]);

  const [isOpenUploadAssignCard, setIsOpenUploadAssignCard] = useState(false);

  // const [showAssign, setShowAssign] = useState(false);
  const [files, setFiles] = useState(null);

  const [isAssigning, setIsAssigning] = useState(false);
  const [successAssign, setSuccessAssign] = useState(false);
  const [error, setError] = useState("");

  const handleAssignCards = async () => {
    try {
      setIsAssigning(true);
      const formData = new FormData();
      formData.append("file", files);
      await assignCardsByExcel(formData);
      setSuccessAssign(true);
      setError("");
      setFiles(null);
    } catch (error) {
      setError(error?.response?.data?.error);
      console.log(error);
    } finally {
      setIsAssigning(false);
    }
  };

  const [isOpenModalReturCard, setIsOpenModalReturCard] = useState(false);

  return (
    <div className={Styles.wrapperDetailPromotorAdminIndosat}>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          color="#1571DE"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <section className={Styles.profiles}>
        <div className={Styles.profilePicture}>
          {isLoadingHit ? (
            <CircleSkeleton h="120px" w="120px" />
          ) : (
            <img
              src={dataDetailPromotor?.profilePicture ?? AVA_DEFAULT}
              alt="profile-pictures"
            />
          )}
          <div>
            <span>Edit Details</span>
            <LinkTo
              to={{
                pathname: `/admin-indosat/promotor-list/${dataDetailPromotor?.employeeId}/edit`,
                state: {
                  isFromPromotorPage: true,
                  isAdminIndosat: true,
                  data: dataDetailPromotor,
                },
              }}
            >
              <Icon
                icon={"pencil"}
                color={"#1571DE"}
                size={"20"}
                className={Styles.icon}
              />
            </LinkTo>
          </div>
        </div>

        <div className={Styles.name}>
          {isLoadingHit ? (
            <Skeleton />
          ) : (
            <h2>{capitalize(dataDetailPromotor?.name)}</h2>
          )}

          <div className={Styles.divider}></div>
          <div className={Styles.labelValue}>
            <div className={Styles.label}>
              <span>NRK</span>
              <span>No Telepon</span>
            </div>
            <div className={Styles.value}>
              {isLoadingHit ? (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                <>
                  <span>{dataDetailPromotor?.employeeId}</span>
                  <span>+{dataDetailPromotor?.phoneNumber}</span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={Styles.totalCardOnHand}>
          <div className={Styles.wrapSimCard}>
            <Icon
              icon={"sim-card"}
              color={"#FEC53D"}
              size={"21"}
              className={Styles.icon}
            />
          </div>
          <span>
            Total Kartu <span>On Hand</span>{" "}
          </span>
          {isLoadingHit ? (
            <div style={{ marginBlock: "8px" }}>
              <Skeleton />
            </div>
          ) : (
            <h1>{dataDetailPromotor?.stockOnHand ?? "0"}</h1>
          )}

          <Button
            variant="outlined"
            sx={{ borderRadius: "8px" }}
            style={{
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "12px",
            }}
            onClick={() => setIsOpenModalReturCard(true)}
          >
            Return Kartu
          </Button>
          <ModalAddNewBatch
            isOpen={isOpenModalReturCard}
            onClose={() => setIsOpenModalReturCard(false)}
            isReturnCard
          />
        </div>

        <div className={`${Styles.totalCardOnHand} ${Styles.totalSale} `}>
          <div className={Styles.wrapSimCard}>
            <Icon
              icon={"cashier-machine"}
              color={"#ABA9FF"}
              size={"18"}
              className={Styles.icon}
            />
          </div>
          <span>Total Penjualan Tunai</span>

          <h1 className={Styles.isSales}>
            {isLoadingHit ? (
              <Skeleton />
            ) : (
              `Rp${dataDetailPromotor?.totalCashOnHand?.toLocaleString("id")}`
            )}
          </h1>
        </div>
      </section>

      <section className={Styles.table}>
        <div className={Styles.head}>
          <h4>Daftar Batch</h4>
          <div className={Styles.wrappBtnDropdown}>
            <Button
              variant="contained"
              style={{ borderRadius: "8px", boxShadow: "unset" }}
              onClick={() => setIsOpenUploadCard(!isOpenUploadCard)}
              startIcon={
                <Icon
                  icon={"Upload"}
                  color={"#fff"}
                  size={"20"}
                  className={Styles.icon}
                />
              }
            >
              Assign Kartu
            </Button>

            {isOpenUploadCard && (
              <div className={Styles.dropdown} ref={dropdownAssignRef}>
                <ul>
                  {["Assign Manual", "Upload Data"].map((t, idx) => (
                    <li
                      onClick={() => setIsOpenUploadAssignCard(t)}
                      key={idx + "type-assign"}
                    >
                      {t}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <ModalAddNewBatch
              isOpen={isOpenUploadAssignCard === "Assign Manual"}
              onClose={() => setIsOpenUploadAssignCard(false)}
              setTriggerFetch={setTriggerFetch}
            />
          </div>
        </div>

        <div className={Styles.table}>
          <Table
            data={templateDataPersonil?.data || []}
            columns={templateDataPersonil.columns}
            withNumbering
            className={Styles.tableClass}
            withPagination
            page={+page}
            setPage={setPage}
            limit={+limit}
            setLimit={setLimit}
            totalData={dataPagination?.totalData || 0}
            totalPage={dataPagination?.totalPages || 0}
            isAdminIndosat
            isLoading={isLoadingHit}
          />

          <Modal
            onClose={() => {}}
            isOpen={isOpenUploadAssignCard === "Upload Data" || successAssign}
          >
            <AddStockModal
              handleDone={() => {
                setIsOpenUploadAssignCard(false);
                setSuccessAssign(false);
              }}
              files={files}
              setFiles={setFiles}
              handleSubmit={handleAssignCards}
              isLoading={isAssigning}
              success={successAssign}
              error={error}
              isAssign
            />
          </Modal>

          <ModalDetailBatchAdminIndosat
            isOpen={openModalDetailBatch ? true : false}
            id={openModalDetailBatch}
            onClose={() => setOpenModalDetailBatch(false)}
            dataProps={templateDataPersonil?.data?.find(
              (el) => el?.id === openModalDetailBatch
            )}
          />
        </div>
      </section>
    </div>
  );
}

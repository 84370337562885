import * as types from "../../typeAdminIndosat";

const initialstate = {
  allDataAccountNumbers: [],
  loadingDataAccountNumbers: false,
  errorAccountNumbers: {},
};

const AccountNumberAdminIndosatReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_ACCOUNT_NUMBERS_BEGIN: {
      return {
        ...state,
        loadingDataAccountNumbers: true,
      };
    }
    case types.FETCH_ALL_ACCOUNT_NUMBERS_ERROR: {
      return {
        ...state,
        loadingDataAccountNumbers: false,
        errorAccountNumbers: action.payload,
      };
    }

    case types.FETCH_ALL_ACCOUNT_NUMBERS: {
      console.log(action, 'ini action')
      return {
        ...state,
        loadingDataAccountNumbers: false,
        allDataAccountNumbers: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default AccountNumberAdminIndosatReducer;

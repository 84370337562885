import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EmployeeAdminIndosatPage from "../../../pages/AdminIndosat/EmployeePage";
import NumberListIndosatPage from "../../../pages/AdminIndosat/NumberIndosatPage";
import PromotorListPage from "../../../pages/AdminIndosat/PromotorList";
import AdminIndosatSales from "../../../pages/AdminIndosat/Sales";
import AbsencesAdminIndosatPage from "../../../pages/AdminIndosat/AbsencePage";
import DetailEmployeeAdminIndosatPage from "../../../pages/AdminIndosat/EmployeePage/Detail";
import NotFound404 from "../../_pageNotFound/NotFound404";
import DashboardAdminIndosatPage from "../../../pages/AdminIndosat/DashboardAdminIndosatPage";
import DetailPromotorAdminIndosatPage from "../../../pages/AdminIndosat/PromotorDetail";
import SettingPage from "../../../pages/AdminIndosat/Setting";
import IndosatAttendanceDetail from "../../../pages/AdminIndosat/AbsenceDetail";
import AdminIndosatTransactionDetail from "../../../pages/AdminIndosat/TransactionDetail";
import EditEmployeeAdminIndosatPage from "../../../pages/AdminIndosat/EditEmployeeeAdminIndosat";

const RoutesAdminIndosat = () => {
  // const token = localStorage.getItem('accessToken');
  const { path } = useRouteMatch();
  // const token = localStorage.getItem('accessToken');

  // if (!token) {
  //   return <Redirect to="/admin-mws" />;
  // }
  return (
    <div>
      <Switch>
        {/* SALARY ADVANCE */}
        <Route exact path={`${path}`} component={DashboardAdminIndosatPage} />
        <Route
          exact
          path={`${path}/employee`}
          component={EmployeeAdminIndosatPage}
        />
        <Route
          exact
          path={`${path}/absence`}
          component={AbsencesAdminIndosatPage}
        />
        <Route
          exact
          path={`${path}/absence/:id`}
          component={IndosatAttendanceDetail}
        />
        <Route
          exact
          path={`${path}/employee/:id`}
          component={DetailEmployeeAdminIndosatPage}
        />
        <Route
          exact
          path={`${path}/employee/edit/:id`}
          component={EditEmployeeAdminIndosatPage}
        />
        <Route exact path={`${path}/absence`} component={NotFound404} />
        <Route
          exact
          path={`${path}/number-list`}
          component={NumberListIndosatPage}
        />
        <Route
          exact
          path={`${path}/promotor-list`}
          component={PromotorListPage}
        />
        <Route
          exact
          path={`${path}/promotor-list/:id`}
          component={DetailPromotorAdminIndosatPage}
        />
        <Route
          exact
          path={`${path}/promotor-list/:id/edit`}
          component={EditEmployeeAdminIndosatPage}
        />
        <Route exact path={`${path}/setting`} component={SettingPage} />
        <Route exact path={`${path}/sales`} component={AdminIndosatSales} />
        <Route
          exact
          path={`${path}/sales/:id`}
          component={AdminIndosatTransactionDetail}
        />
        <Route path="*" component={NotFound404}></Route>
      </Switch>
    </div>
  );
};

export default RoutesAdminIndosat;

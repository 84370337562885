import React, { useEffect, useRef } from "react";
import Styles from "./ModalSuccessEmployeeV2.module.css";
import CHECKED_BLUE from "../../../../assets/images/success-icon-add-scheme.png";
import { useHistory } from "react-router";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

export default function ModalSuccessEmployeeV2(props) {
  const { onOpen, isAdminIndosat } = props;
  const history = useHistory();
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);

  useEffect(() => {
    if (!isAdminIndosat) {
      setTimeout(() => {
        onOpen(false);
        // history.go(-1)
        history.push(`/superadmin/employee`);
      }, 3000);
    }
  }, [history, isAdminIndosat, onOpen]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);

  return (
    <div className={Styles.ModalDeleteBackground}>
      <div className={Styles.ModalContainerContent}>
        <div className={Styles.WrapperItemModalSuccess}>
          <div className={Styles.Image}>
            <img src={CHECKED_BLUE} alt="checked-success" />
          </div>
          <div className={Styles.Content}>
            <span>Request send</span>
          </div>
        </div>
      </div>
    </div>
  );
}

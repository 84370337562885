import axios from "axios";
import * as types from "../../typeAdminIndosat";
import { API } from "../../api";

const token = localStorage.getItem("accessToken");

export const fetchAllSales = (params) => {
  return (dispatch) => {
    dispatch({ type: types.SALES_BEGIN });
    axios
      .get(`${API}/transaction/fetchAdmin/${params}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_SALES,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SALES_ERROR,
          payload: err,
        });
        console.log("ERROR", err.response);
      });
  };
};

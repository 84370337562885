import axios from "axios";
import { API } from "../../api";
import * as types from "../../typeAdminIndosat";

export const fetchPromotors = (page, limit, search, sort = '') => {
  const token = localStorage.getItem("accessToken");

  return (dispatch) => {
    dispatch({ type: types?.FETCH_ALL_PROMOTORS_BEGIN });
    axios
      .get(
        `${API}/promotor`,
        {
          headers: {
            Authorization: token,
          },
          params: { page, limit, search, sort },
        }
      )
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_PROMOTORS,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        console.log(err, 'ini error')
        dispatch({
          type: types.FETCH_ALL_PROMOTORS_ERROR,
          payload: err?.response?.data,
        });
        console.log("ERROR", err);
      });
  };
};


import { Fragment, useEffect, useMemo, useState } from "react";
import EMPTY_HANDLER from "../../../assets/images/pana.png";
import useWindowSize from "../../../hooks/useWindowSize";
import CustomTablePagination from "../../_pagination/CustomTablePagination";
import Skeleton from "../../Atom/Skeleton";
import TabHeader from "../../Atom/TabHeader";
import Styles from "./style.module.scss";

export default function Table({
  columns,
  data = [],
  totalData = 0,
  totalPage = 0,
  withPagination = false,
  page = 1,
  setPage = () => { },
  limit = 10,
  setLimit = () => { },
  isLoading = false,
  className = "",
  withNumbering = false,
  // expandComponent = (row) => <span>data</span>,
  withCheckbox,
  isChecked,
  setIsChecked,
  isDisabled,
  isDetailChart = false,
  onClickRow,
  widthNumber = "28px",
  isBorder = false,
  isActive = false,
  isActiveKey = "name",
  bgHeader = "#EDEDED",
  isBorderBottomTableHead = false,
  classNameForTableHead = "",
  isTableHeadSticky = false,
  isCustomEmptyHandler = false,
  heightEmpty = "50px",
  customEmptyHandler = () => <span>custom empty handler</span>,
  heightEmptyData = "400px",
  classNameCustomTh,
  classNameCustomTd,
  textNumber = "No",
  isAdminIndosat = false,
  ...props
}) {
  const { width } = useWindowSize();

  const columnsToMap = useMemo(() => {
    if (width > 851) {
      return columns?.filter((obj) => !obj?.showOnMobile);
    }
    return columns?.filter((obj) => !obj?.hideOnMobile);
  }, [columns, width]);

  const [dataToMap, setDataToMap] = useState(data);

  useEffect(() => {
    setDataToMap(data);
  }, [data]);

  return (
    <div
      className={`${Styles.container} ${className}`}
      style={{ minHeight: isDetailChart ? "100px" : "" }}
      {...props}
    >
      <div
        className={Styles.tableWrapper}
        style={{
          // height:
          //   !isLoading && !data?.length && !isDetailChart ? "400px" : "100%",
          overflowY: isTableHeadSticky ? "unset" : "",
        }}
      >
        <table className={Styles.table}>
          <thead>
            <tr>
              {withNumbering && (
                <th
                  text="No"
                  style={{
                    width: width > 768 ? widthNumber : "20px",
                  }}
                >
                  <TabHeader
                    text={textNumber}
                    style={{ width: width > 768 ? widthNumber : "20px" }}
                  />
                </th>
              )}
              {withCheckbox && (
                <th
                  style={{
                    width: "48px",
                    backgroundColor: bgHeader,
                    borderBottom: isBorderBottomTableHead
                      ? "1px solid #dee2e7"
                      : "unset",
                  }}
                  className={`${classNameForTableHead}`}
                ></th>
              )}
              {columnsToMap?.map((c, cI) => (
                <th
                  key={cI}
                  style={{
                    textAlign: c?.align || "left",
                    minWidth: c?.width || "auto",
                    backgroundColor: bgHeader,
                    borderBottom: isBorderBottomTableHead
                      ? "1px solid #dee2e7"
                      : "unset",
                    height: c?.height,
                  }}
                  className={`${classNameForTableHead} ${classNameCustomTh} `}
                >
                  {c?.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading
              ? dataToMap?.map((d, dI) => (
                <Fragment key={dI}>
                  <tr
                    key={dI}
                    onClick={() => (onClickRow ? onClickRow(d) : () => { })}
                    style={{
                      cursor: onClickRow ? "pointer" : "auto",
                    }}
                    is_active={(d[isActiveKey] === isActive).toString()}
                  >
                    {withNumbering && (
                      <td style={{width: '85px'}}>{+dI + 1 + (+page - 1) * +limit}</td>
                    )}

                    {columnsToMap?.map((c, cI) => (
                      <td
                        key={cI}
                        style={{
                          textAlign: c?.align || "left",
                          width: c?.width || "auto",
                          padding: "8px 16px ",
                        }}
                        className={`${classNameCustomTd}`}
                        onClick={
                          width < 851 && c?.clickExpand
                            ? () =>
                              setDataToMap((prev) =>
                                prev?.map((obj) => {
                                  return {
                                    ...obj,
                                    rowExpanded:
                                      obj?.id === d?.id
                                        ? !obj?.rowExpanded
                                        : obj?.rowExpanded,
                                  };
                                })
                              )
                            : () => { }
                        }
                      >
                        {c?.renderData ? (
                          c?.renderData(d)
                        ) : (
                          <span
                            style={{ display: "block", width: "100%" }}
                            onClick={() =>
                              setDataToMap((prev) =>
                                prev?.map((obj) => {
                                  return {
                                    ...obj,
                                    rowExpanded:
                                      obj?.id === d?.id
                                        ? !obj?.rowExpanded
                                        : obj?.rowExpanded,
                                  };
                                })
                              )
                            }
                          >
                            {d[c?.name] || "-"}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>

                  {/* {d?.rowExpanded && (
                      <tr
                        className={Styles.expandedRow}
                        style={{ background: "transparent" }}
                      >
                        {withNumbering && <td />}
                        <td
                          colSpan={
                            withNumbering
                              ? columnsToMap?.length + 1
                              : columnsToMap?.length + 2
                          }
                        >
                          {expandComponent(d)}
                        </td>
                      </tr>
                    )} */}
                </Fragment>
              ))
              : [...Array(limit)]?.map((s, sI) => (
                <tr key={sI}>
                  {withNumbering && (
                    <td style={{width: '50px'}}>
                      {<Skeleton w="30px"/>}
                    </td>
                  )}
                  {columnsToMap?.map((c, cI) => (
                    <td
                      key={cI}
                    // className={column?.tdClassName || ''}
                    >
                      {c?.skeleton || <Skeleton />}
                    </td>
                  ))}
                </tr>
              ))}
            {dataToMap.length > 0 &&
              dataToMap.length < limit &&
              !isLoading &&
              Array.from(
                { length: Math.max(0, limit - (dataToMap.length || 0)) },
                (_, k) => k
              ).map((_, idxLength) => (
                <tr key={idxLength} className={Styles.emptyData}>
                  {Array.from({
                    length:
                      columnsToMap.length +
                      (withCheckbox ? 1 : withNumbering && 1),
                  }).map((_, idxForEmpty) => (
                    <td
                      key={idxForEmpty}
                      style={{
                        height: heightEmpty,
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {!isLoading &&
          !data?.length &&
          !isDetailChart &&
          (isCustomEmptyHandler ? (
            customEmptyHandler()
          ) : (
            <div
              className={Styles.emptyHandlerBox}
              style={{ height: heightEmptyData }}
            >
              <div className={Styles.images}>
                <img src={EMPTY_HANDLER} alt="" />
              </div>
              <span>Data Belum Tersedia</span>
              <span>Belum ada data yang ditambahkan ke dalam daftar</span>
            </div>
          ))}
      </div>

      {withPagination && (
        <CustomTablePagination
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          totalData={totalData}
          totalPage={totalPage}
          isAdminIndosat={isAdminIndosat}
          className={Styles.borderPagination}
        />
      )}
    </div>
  );
}

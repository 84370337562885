/*eslint-disable no-unused-vars*/

import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container, Button, Dialog } from "@mui/material";
import { Helmet } from "react-helmet";
import { TableWorkOnLayout } from "../../../../components/External";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeesWithoutWorkOn } from "../../../../redux/actions/External/workOnExternalActions";
import {
  fetchClientByCodeExternal,
} from "../../../../redux/actions/External/clientExternalActions";
import fetchDetailClientExternalAsync from "../../../../async/detailClientExternal/fetchDetailClientExternalAsync";
import setWorkOn from "../../../../async/workOn/setWorkOn";
import deleteWorkOn from "../../../../async/workOn/deleteWorkOn";
import SUCCESS_VECTOR from "../../../../assets/images/data-saved-vector.png";
import Styles from "./TableWorkOn.module.css";

export default function TableWorkOn() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const clientCode = window.sessionStorage.getItem("selectedClientCodeExternal") || window.localStorage.getItem("clientCode");
  const { allWorkOnEmployeeExternal: attendees } = useSelector((state) => state.workOnExternal);
  const { clientExternalByCode: dataClientExternal } = useSelector((state) => state.clientExternal);
  // const attendancePlanId = dataClientExternal?.attendancePlan?._id;
  const [detailExternal, setDetailExternal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // useEffect(() => {
  //   dispatch(fetchDetailClientExternal(token, attendancePlanId, clientCode))
  // },[dispatch, token, attendancePlanId, clientCode])

  const attendancePlanId = useMemo(() => {
    return dataClientExternal?.attendancePlan?._id;
  }, [dataClientExternal]);

  const workHours = useMemo(() => {
    return detailExternal?.workHours;
  }, [detailExternal]);

  useEffect(() => {
    dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
  }, [token, clientCode, dispatch]);

  useEffect(() => {
    dispatch(fetchClientByCodeExternal(token, clientCode));
  }, [dispatch, token, clientCode]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchDetailClientExternalAsync(
        token,
        attendancePlanId,
        clientCode
      );
      setDetailExternal(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if(clientCode) {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendancePlanId, clientCode, token]);

  const handleSetWorkOn = async (data, workId) => {
    try {
      await setWorkOn(token, { employeeIds: data, workOn: workId });
      setOpenSuccessModal(true);
      await getData();
      dispatch(fetchClientByCodeExternal(token, clientCode));
      dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id, selectedId) => {
    try {
      await deleteWorkOn(token, { employeeIds: selectedId });
      await getData();
      dispatch(fetchClientByCodeExternal(token, clientCode));
      dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attedance</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          Work On
        </h2>
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TableWorkOnLayout
            datas={attendees}
            attendancePlanId={attendancePlanId}
            detaiClient={detailExternal}
            workHoursData={workHours}
            loadingWorkOn={isLoading}
            handleSetWorkOn={(datas, workId) => handleSetWorkOn(datas, workId)}
            handleDelete={(id, selectedId) => handleDelete(id, selectedId)}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenSuccessModal(false)}
        open={openSuccessModal}
        fullWidth
        maxWidth="xs"
      >
        <div className={Styles.ModalControl}>
          <div className={Styles.ItemTopModal}>
            <img
              src={SUCCESS_VECTOR}
              alt="success"
              className={Styles.ModalIcon}
            />
          </div>
          <div className={Styles.ContentModal}>
            <div className={Styles.TextModal}>
              <span>Setting Saved</span>
              <span>Employee list successfully added</span>
            </div>
            <div className={Styles.WrapperButtonModal}>
              <Button
                style={{
                  backgroundColor: "#1571DE",
                  textTransform: "none",
                  color: "#FFFFFF",
                  width: 100,
                  height: 40,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpenSuccessModal(false);
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}

/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  UserDetailMenuAdmin2,
  UserDetailMenuAdmin,
  AppEmployeeSA
} from "../../../../components/SuperAdmin/_user/";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployees } from "../../../../redux/actions/SuperAdmin/employeesActions";
import { Page } from "@react-pdf/renderer";
import fetchClientCode from "../../../../async/client/fetchClientCode";
// import { Link } from "react-router-dom";

// employees: action.payload,
//   loadingAllEmployee: false

export default function EmployeeSA() {
  const pksClient = localStorage.getItem("clients");
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { employees, loadingAllEmployee, totalPage } = useSelector((state) => state.employees)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [dataClientCodes, setDataClientCodes] = useState([]);
  const client = window.sessionStorage.getItem("selectedClient");
  const defaultClientCode = localStorage.getItem('clientCode')
  const defaultClientName = localStorage.getItem('clientName')
  const [position, setPosition] = useState('')
  const [name, setName] = useState('')
  
  const defaultClient = { codes: defaultClientCode, name: defaultClientName }

  const [selectedClient, setSelectedClient] = useState(defaultClient);
  // const parsedPks = JSON.parse(pksClient);

  // const pksNumberClient = useMemo(() => {
  //   return parsedPks?.map(el => el.contractNumbers)
  // },[parsedPks])


  
  // const selectedPksNumber = useMemo(() => {
  //   let pks_number = "";

  // },[])

  useEffect(() => {
    if (defaultClientCode && defaultClientName){
      window.sessionStorage.setItem("selectedClientCode", defaultClientCode);
      window.sessionStorage.setItem("selectedClientName", defaultClientName);
      window.sessionStorage.setItem("selectedPosition", "");
    }
  },[defaultClientName, defaultClientCode])

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Employee</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Employee
        </h2>
      </div>

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* <UserDetailMenuAdmin2/> */}
          <AppEmployeeSA
            employees={employees}
            loading={loadingAllEmployee}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            dataClientCodes={dataClientCodes}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            clientName={selectedClient?.name || defaultClientName}
            setPosition={setPosition}
            position={position}
            setName={setName}
            name={name}
            defaultClient={defaultClient}
            // pksNumberClient={pksNumberClient}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

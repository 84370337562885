import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import React from "react";
import Styles from "./style.module.scss";
import { Dialog } from "@mui/material";

import RobotoRegular from "../../../assets/font/roboto/Roboto-Regular.ttf";
import RobotoMedium from "../../../assets/font/roboto/Roboto-Medium.ttf";
import RobotoBold from "../../../assets/font/roboto/Roboto-Bold.ttf";
import MWSLogo from "../../../assets/images/mws-logo.png";
import PermataLogo from "../../../assets/images/poweredby-permata-logo.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 400,
    },
    {
      src: RobotoMedium,
      fontWeight: 500,
    },
    {
      src: RobotoBold,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: 20,
    paddingBottom: 54,
  },
  pageHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 12,
    borderBottom: "1px solid #EAEAEA",
    marginBottom: 16,
  },
  logo: {
    width: "76px",
    height: "43px",
  },
  logo2: {
    width: "163px",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 12,
    color: "#65D072",
    marginBottom: 12,
  },
  description: {
    flexDirection: "row",
    display: "flex",
    rowGap: 16,
    columnGap: 16,
    marginBottom: 12,
  },
  section: {
    flex: 1,
    marginRight: 16,
  },
  descHeader: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
  },
  descSubHeader: {
    fontSize: 12,
    fontWeight: "500",
    marginBottom: 2,
  },
  address: {
    fontSize: 11,
    color: "#6D6D6D",
    fontWeight: "400",
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  rowLabel: {
    fontSize: 11,
    color: "#6D6D6D",
    fontWeight: "400",
    width: 110,
  },
  rowValue: {
    fontSize: 11,
    fontWeight: "medium",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1pt solid #DFE4EA",
    borderBottom: "1pt solid #DFE4EA",
    marginBottom: 5,
    backgroundColor: "#F5F5F5",
    padding: "7px 18px",
    rowGap: 18,
  },
  tableHeaderCell1: {
    width: "30px",

    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    color: "#0A2540",
  },
  tableHeaderCell2: {
    width: "140px",

    textAlign: "left",
    fontSize: 12,
    fontWeight: "bold",
    color: "#0A2540",
    marginLeft: 18,
  },
  tableHeaderCell3: {
    flex: 1,
    textAlign: "right",
    fontSize: 12,
    fontWeight: "700",
    color: "#0A2540",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1pt solid #eee",
    paddingVertical: 5,
    paddingHorizontal: 18,
    rowGap: 18,
  },
  tableCell1: {
    width: "30px",
    textAlign: "center",
    fontSize: 10,
  },
  tableCell2: {
    width: "140px",
    textAlign: "left",
    fontSize: 10,
    marginLeft: 18,
  },
  tableCell3: {
    flex: 1,
    textAlign: "right",
    fontSize: 10,
  },
  footerText: {
    fontSize: 11,
    fontWeight: "400",
  },
  footer: {
    position: "absolute",
    bottom: 14,
    left: 20,
    right: 20,
    flexDirection: "row",
  },
  totalPriceContainer: {
    alignItems: "flex-end",
  },
  totalPrice: {
    marginTop: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 18,
    width: 342,
    float: "right",
  },
  paymentMethodContainer: {
    paddingTop: 12,
    marginTop: 28,
    borderTop: "1pt solid #DFE4EA",
  },
});

const ReceiptPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View fixed style={styles.pageHeader}>
        <Image src={MWSLogo} style={styles.logo} />
        <Image src={PermataLogo} style={styles.logo2} />
      </View>
      <View>
        <Text style={styles.header}>Receipt</Text>
        <Text style={styles.subHeader}>{data.code}</Text>
      </View>

      <View style={styles.description}>
        <View style={styles.section}>
          <Text style={styles.descHeader}>Diterbitkan atas nama</Text>
          <Text style={styles.descSubHeader}>PT Permata Indo Sejahtera</Text>
          <Text style={styles.address}>
            Jl. Raya Kby. Lama No. 226 Blok B2 - B4 RT.10/RW.1, Grogol Sel.,
            Kec. Kby. Lama Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta
            12220
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.descHeader}>Untuk</Text>
          <View style={styles.row}>
            <Text style={styles.rowLabel}>Pembeli</Text>
            <Text style={styles.rowValue}>: {data.store.ownerName}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.rowLabel}>Toko</Text>
            <Text style={styles.rowValue}>
              : {data.name}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.rowLabel}>Tanggal Pembelian</Text>
            <Text style={styles.rowValue}>
              : {moment(data.createdAt).format("DD MMMM YYYY")}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.rowLabel}>Jumlah Kartu</Text>
            <Text style={styles.rowValue}>: {data.items.length}</Text>
          </View>
        </View>
      </View>

      {/* Table Header */}
      <View style={styles.tableHeader}>
        <Text style={styles.tableHeaderCell1}>No</Text>
        <Text style={styles.tableHeaderCell2}>Nomor Kartu</Text>
        <Text style={styles.tableHeaderCell3}>Total Harga</Text>
      </View>

      {/* Table Rows */}
      {data.items.map((item, idx) => (
        <View style={styles.tableRow} key={idx} wrap={false}>
          <Text style={styles.tableCell1}>{idx + 1}</Text>
          <Text style={styles.tableCell2}>{item.msisdn}</Text>
          <Text style={styles.tableCell3}>
            Rp{(+item.price).toLocaleString("id")}
          </Text>
        </View>
      ))}

      <View style={styles.totalPriceContainer}>
        <View style={styles.totalPrice}>
          <Text style={styles.descHeader}>Total Tagihan</Text>
          <Text style={styles.descHeader}>
            Rp{data.paid.toLocaleString("id")}
          </Text>
        </View>
      </View>

      <View style={styles.paymentMethodContainer}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: "#C7C7C7",
            marginBottom: 4,
          }}
        >
          Metode pembayaran
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 700, color: "#0A2540" }}>
          {data.isTempo
            ? "Tempo"
            : data.method === "CASH"
            ? "Tunai"
            : "Transfer"}
          {data.isTempo &&
            data.statusTransaction === "APPROVED" &&
            `${" "}- ${data.method === "CASH" ? "Tunai" : "Transfer"}`}
        </Text>
        {data.isTempo && (
          <Text
            style={{
              fontSize: 14,
              fontWeight: 700,
              color: "#0A2540",
              marginBottom: 2,
            }}
          >
            {moment(data.tempoAt).format("DD MMMM YYYY")}
          </Text>
        )}
      </View>

      {/* Footer */}
      <View style={styles.footer} fixed>
        <Text
          style={{
            ...styles.footerText,
            color: "#0A2540",
            flex: 1,
          }}
        >
          Receipt ini sah dan di cetak oleh sistem
        </Text>
        <Text
          style={{
            ...styles.footerText,
            color: "#C7C7C7",
            marginRight: 2,
          }}
        >
          Tanggal dibuat :
        </Text>
        <Text style={{ fontSize: 11, fontWeight: "500", color: "#0A2540" }}>
          {moment().format("DD MMMM YYYY, HH:mm")} WIB
        </Text>
      </View>
    </Page>
  </Document>
);

export default function TransactionDetail() {
  const location = useLocation();
  return (
    <Dialog open={true} fullScreen>
      <div className={Styles.pdfContainer}>
        <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
          <ReceiptPDF data={location.state.data} />
        </PDFViewer>
      </div>
    </Dialog>
  );
}

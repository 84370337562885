import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import SearchbarSA from "../../utils/SearchbarSA/SearchbarSA";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Menu, styled, TextField } from "@mui/material";
import ExportCSV from "../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import toHoursAndMinutes from "../../utils/ToHoursAndMinute/toHoursAndMinute";
import { useSelector } from "react-redux";
import { API, API_IMAGE } from "../../../redux/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha } from "@mui/system";
import axios from "axios";
import { fs } from "fs";
import EXCEL_ICON from "../../../assets/images/excel.png";
import TabHeader from "../../Atom/TabHeader";
import Table from "../Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ExcelJS = require("exceljs");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    display: "flex",
    justifyContent: "flex-start",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiList-root": {
      width: "100%",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

export default function AbsencesAdminIndosat() {
  const [dateFrom, setDateFrom] = useState(moment().format(""));
  const [dateTo, setDateTo] = useState(moment().format(""));
  // const { dataAbsences, loadingDataEmployee } = useSelector(
  //   (state) => state.employeeExternal
  // );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [nameAttendance, setNameAttendance] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [dataAbsences, setDataAbsences] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const history = useHistory();

  const handleDownloadDetailedOvertime = async () => {
    const url = `${API}/attendance/admin/download/overtime?&startDate=${moment(
      new Date(persistDateFrom).getTime()
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(persistDateTo).getTime()
    ).format("YYYY-MM-DD")}`;
    const headers = { "Content-Type": "blob", Authorization: token };
    const config = { method: "GET", url, responseType: "arraybuffer", headers };

    try {
      setLoading(true);
      const response = await axios(config);
      const outputFilename = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();

      fs?.writeFileSync(outputFilename, response?.data);
    } catch (error) {
      throw Error(error);
    } finally {
      setLoading(false);
    }
  };

  const persistDateFrom = useMemo(() => {
    let temp = window.sessionStorage.getItem("dateFrom");
    if (temp) {
      return temp;
    } else {
      return dateFrom;
    }
  }, [dateFrom]);

  const persistDateTo = useMemo(() => {
    let temp = window.sessionStorage.getItem("dateTo");

    if (temp) {
      return temp;
    } else {
      return dateTo;
    }
  }, [dateTo]);

  useEffect(() => {
    const fetchDataAbsences = async () => {
      setLoadingData(true);
      try {
        const response = await axios.get(
          `${API}/attendance-business/?startDate=${moment(
            persistDateFrom
          ).format("YYYY-MM-DD")}&endDate=${moment(persistDateTo).format(
            "YYYY-MM-DD"
          )}&clientName=PT INDOSAT TBK`,
          {
            headers: {
              // Authorization: "a5434ddabf0faf50bd0f985b27c84e64",
              Authorization: token,
            },
          }
        );
        setDataAbsences(response.data.response);
        setLoadingData(false);
      } catch (error) {
        console.error(error);
        setLoadingData(false);
      }
    };
    fetchDataAbsences();
  }, [persistDateFrom, persistDateTo]);
  const handleSetDateFrom = (newVal) => {
    setDateFrom(newVal);
    window.sessionStorage.setItem("dateFrom", newVal);
  };
  const attendanceArr = () => {
    const array = [];
    for (let i = 0; i < dataAbsences?.length; i++) {
      const absences = dataAbsences[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...dataAbsences[i],
          timeIn: absences[j].startTime
            ? moment(absences[j].startTime).format("HH:mm:ss")
            : "-",
          timeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("L")
              : "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].startTime).format("YYYY"),
          isLate: absences[j]?.isLate,
          totalWorkHour: toHoursAndMinutes(
            absences[j]?.accumulate?.workInMinute
          ),
          region: absences[j]?.area,
          remark: absences[j]?.remark?.includes("::")
            ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2])
            : capitalizeFirstLetter(absences[j]?.remark),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
          imageAttendance: absences[j].images[0]?.uri
            ? absences[j].images[0]?.uri?.replace(
                `https://myworkspace.id/api/image/`,
                `${API_IMAGE}`
              )
            : "-",
          requestTime: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("HH:mm:ss")
            : "-",
          requestDate: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("L")
            : "",
        });
      }
    }
    return array;
  };

  const dataToExport = attendanceArr();

  const handleSetDateTo = (newVal) => {
    setDateTo(newVal);
    window.sessionStorage.setItem("dateTo", newVal);
  };

  const templateDataPersonil = useMemo(() => {
    return {
      data: dataAbsences
        .slice((page - 1) * limit, page * limit)
        .map((data, idx) => ({
          ...data,
          id: idx,
          name: data.name,
          profilePicture: data.profilePicture,
          NRK: data.employeeId,
          position: data.jobTitle,
          region: data.area,
          attend: data.attend,
          leave: data.alfa,
          sick: data.sick,
        })),
      totalData: 20,
      columns: [
        {
          name: "name",
          title: <TabHeader text="Employee Name" />,
          renderData: (row) => (
            <div
              className={Styles.withProfilePic}
              onClick={() =>
                history.push(`/admin-indosat/absence/${row?.employeeId}`, {
                  startDate: persistDateFrom,
                  endDate: persistDateTo,
                  summary: row,
                  clientName: row.client,
                })
              }
            >
              <img className={Styles.img} src={row.profilePicture} alt="" />
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "position",
          title: <TabHeader text="Position" />,
        },

        {
          name: "region",
          title: <TabHeader text="Region" />,
        },
        {
          name: "attend",
          title: <TabHeader text="Attend" />,
          renderData: (row) => <span>{row.attend || 0}</span>,
        },
        {
          name: "leave",
          title: <TabHeader text="Leave" />,
          renderData: (row) => <span>{row.leave || 0}</span>,
        },
        {
          name: "sick",
          title: <TabHeader text="Sick" />,
          renderData: (row) => <span>{row.sick || 0}</span>,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAbsences, limit, page, persistDateFrom, persistDateTo]);

  return (
    <div className={Styles.container}>
      <p>Absensi</p>
      <div className={Styles.controlWrapper}>
        <div className={Styles.searchWrapper}>
          <SearchbarSA
            value={nameAttendance}
            onChange={(e) => setNameAttendance(e.target.value)}
            searchWidth="316px"
            placeholder="Search by name"
          />
        </div>
        <div className={Styles.filterWrapper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={Styles.RangeDate}>
              <DatePicker
                value={persistDateFrom || dateFrom}
                views={["year", "month", "day"]}
                onChange={handleSetDateFrom}
                format="DD-MM-YYYY"
                disabled={loadingData}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    sx={{ background: "var(--main-bg)", width: 150 }}
                    size="small"
                  />
                )}
              />
            </div>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={Styles.RangeDate}>
              <DatePicker
                // sx={{ width: "50%" }}
                value={persistDateTo || dateTo}
                views={["year", "month", "day"]}
                disabled={loadingData}
                onChange={handleSetDateTo}
                format="DD-MM-YYYY"
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    sx={{ background: "var(--main-bg)", width: 150 }}
                    size="small"
                  />
                )}
              />
            </div>
          </LocalizationProvider>

          <div className={Styles.ActionButton}>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              endIcon={<ExpandMoreIcon fontSize="20px" />}
              className={Styles.DownloadExcelButton}
              sx={{ width: "66px !important" }}
            >
              <img
                src={EXCEL_ICON}
                alt="excel-icon"
                className={Styles.ImageButton}
              />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              <ExportCSV
                data={dataToExport.map((el, ind) => ({
                  "No. ": ind + 1,
                  Nama: el?.name,
                  //   Company: el?.client,
                  Position: el.jobTitle,
                  Nrk: el?.employeeId,
                  Month: el.month,
                  Year: el.year,
                  "Date in": el.dateTimeIn,
                  "Date out": el.dateTimeOut,
                  "Time in": el.timeIn,
                  "Time out": el.timeOut,
                  "Coordinate Point": `${el.latitude} ${el.longitude}`,
                  Region: el.region,
                  "Status Absence": el.status,
                  Remark: el.remark,
                  "Request Date": el?.requestDate,
                  "Request Time": el?.requestTime,
                  "Photo": el?.imageAttendance || "-",
                }))}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Default"
              />
            </StyledMenu>
          </div>
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <Table
          data={templateDataPersonil.data}
          columns={templateDataPersonil.columns}
          withNumbering
          className={Styles.tableClass}
          withPagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalData={dataAbsences.length}
          totalPage={Math.ceil(dataAbsences.length / limit)}
          isAdminIndosat
          isLoading={loadingData}
        />
      </div>
    </div>
  );
}
